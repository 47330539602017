import React, {Component} from 'react'


class FullPageLayout extends Component {
    render() {
        return (
            <div className={`main-wrapper`}>
                {this.props.children}
            </div>
        )
    }
}

export default FullPageLayout
