import React, {Component} from 'react';
import {IMG_PATH, VIDEO_PATH} from '../../constants/index';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

class Ads extends Component {

    render() {

        let ad = this.props.ads.box && this.props.ads.box[this.props.adPosition] ? this.props.ads.box[this.props.adPosition] : {};
        let nodeSrc = '';
        let adType = ad && ad.ad_type ? ad.ad_type : '';
        if(adType === 'Display') {
            nodeSrc = IMG_PATH + ad['display_ad_material'];
        }
        if(adType === 'Video') {
            nodeSrc = VIDEO_PATH + ad['video_ad_material'];
        }
        //console.log(ad)
        return(
            <div className="section-right">
                <div className="each-adv">
                    {(adType === 'Display' || adType === 'Video') &&
                    <a target="_blank"
                       href={ad['url-display-ad'] || ""}
                       rel="noreferrer"
                    >
                        {adType === 'Display' &&
                        <LazyLoadImage
                            alt={`Ad Image`}
                            src={nodeSrc}
                            effect="opacity"/>
                            /*<img src={nodeSrc}/>*/
                        }
                        {adType === 'Video' &&
                        <video src={nodeSrc} autoPlay={true}/>
                        }
                    </a>
                    }
                </div>
            </div>
        );
    }

}
export default Ads;