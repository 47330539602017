import React, { Component } from "react";
import {Link} from 'react-router-dom';
import * as actionReducers from "../../reducers";
import {connect} from "react-redux";
import * as ActionCreators from "../../actions";

class Subscribe extends Component {

    constructor(props) {
        super();
        this.selectPlan =  this.selectPlan.bind(this);
    }
    selectPlan(plan) {
        let ud = localStorage.getItem('ud') ? JSON.parse(atob(localStorage.getItem('ud'))) : {};
        let refer = new URL(window.location.href).searchParams.get("refer");
        if(refer === '' || this.props.loggedIn === false || (ud.email  && ud.user_category === 'Bsynapse User')) {
            this.props.selectPlan(plan);
        }
        if(refer === 'upgrade' && this.props.loggedIn === true) {
            this.props.setDataToUpgrade(plan, 'payment', localStorage.getItem('ud') ? JSON.parse(atob(localStorage.getItem('ud'))) : {})
        }
    }
    componentDidMount() {
        this.props.findSubscriptionPlans();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let refer = new URL(window.location.href).searchParams.get("refer");
        let showModal = (refer === 'stories' || refer === 'bookmark') ? true : false;
        let message = (refer === 'stories') ?
                'Please login to read the full story.'
            :
            'Please login to read the full story.'
        this.props.toggleModal(
            showModal,
            '',
            message,
            this.props.toggleModal,
            false,
            this.props.toggleModal,
            'md'
        );
    }

    render() {
        let plans = this.props.subscriptionPlans.data;
        let refer = new URL(window.location.href).searchParams.get("refer");
        let activePlan = localStorage.getItem('ud') ? JSON.parse(atob(localStorage.getItem('ud')))['Subscription Plan'] : ''
        console.log(plans)
        let reference = '';
        if(refer !== '' || refer !== null) {
            reference = '?refer=' + refer;
        }

        return (
            <React.Fragment>

                <div className="anchor-links">
                    <Link onClick={(e) => this.props.changeSection('')}>Home</Link>
                    {/*/  <Link className="current-page">Subscription Plans</Link>*/}
                </div>


                {/*{localStorage.getItem("platform") !== 'ios' &&*/}
                {/*    <div className="boiler-panel">*/}
                {/*        <div className="boiler-banner">*/}
                {/*            <h5>Subscription Plans</h5>*/}
                {/*        </div>*/}
                {/*        <div className="boiler-content">*/}
                {/*            <p><span className="font-bold">Hedge Ohari</span> is a finance journal that provides its*/}
                {/*                readers comprehensive knowledge and insight about the various aspects of financial*/}
                {/*                planning.</p>*/}
                {/*            <p><span className="font-bold">Choose a subscription</span></p>*/}

                {/*            <ul className="price-tag-box">*/}
                {/*                {plans.map((plan, planIndx) =>*/}
                {/*                    <li key={`subscribe-plan${planIndx}`}*/}
                {/*                        className={`${plan.text_plan !== activePlan ? '' : ''}`}>*/}
                {/*                        <Link to={`/plan${reference}`} className="price-tag-box-anchor"*/}
                {/*                              onClick={(e) => this.selectPlan(plan)}>*/}
                {/*                            <div className="price-tag-left">*/}
                {/*                                <h5>{plan.text_plan}</h5>*/}
                {/*                                <p>{plan.number_months} Months*/}
                {/*                                    Subscription {plan.text_subscription_price}</p>*/}
                {/*                            </div>*/}
                {/*                            <div className="price-tag-right">*/}
                {/*                                <h5>{plan.text_offer_price}</h5>*/}
                {/*                                <p>You save {plan.text_savings}</p>*/}
                {/*                            </div>*/}
                {/*                        </Link>*/}
                {/*                    </li>*/}
                {/*                )}*/}

                {/*            </ul>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}
                {/*{localStorage.getItem("platform") === 'ios' &&*/}
                {/*    <div className="boiler-panel">*/}
                {/*        <div className="boiler-banner">*/}
                {/*            <h5>Please login to read the full story.  </h5>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}

            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        loader: actionReducers.getLoader(state),
        subscriptionPlans: actionReducers.getSubscriptionPlans(state),
    }
}
export default connect(mapStateToProps, {
    findSubscriptionPlans: ActionCreators.findSubscriptionPlans
})(Subscribe);
