import {Component} from 'react';



class OhariModal extends Component {


    render() {
        //console.log(this.props)
        return(
            <div className={`h-modal fade ${this.props.showModal === true ? 'show' : ''}`} style={this.props.showModal === true ? {display:"block"} : {display: "none"}}>
                <div className={`modal-dialog modal-dialog-centered modal-${this.props.modalSize}`}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5>{this.props.modalHeader}</h5>
                            {/*<button className="close-mod" onClick={(e) => this.props.toggleModal(false)}/>*/}
                        </div>
                        <div className="modal-body">
                            <p dangerouslySetInnerHTML={{__html: this.props.modalMessage}}>
                            </p>
                        </div>
                        <div className="modal-footer">
                            {(this.props.modalShowCancel === true && this.props.modalCancelAction !== '') &&
                            <button className={`general-btn grey`}
                                    onClick={this.props.modalCancelAction}>Cancel</button>
                            }
                            {this.props.modalOKaction !== '' &&
                            <button className={`general-btn`} onClick={this.props.modalOKaction}>OK</button>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default OhariModal;
