import React, { Component } from "react";
import {Link} from 'react-router-dom';
import * as actionReducers from "../../reducers";
import {connect} from "react-redux";
import * as ActionCreators from "../../actions";


class ContactUs extends Component {
    constructor(props) {
        super();
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
            error: {
                name: '',
                email: '',
                subject: '',
                message: ''
            }

        }
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }
    onSubmit() {
        let errors = 0;
        let error = {
            name: "",
            email: "",
            subject: "",
            message: ""
        };

        if (this.state.name === "") {
            error["name"] = "Enter name";
            errors++;
        }
        if (this.state.email === "") {
            error["email"] = "Enter email";
            errors++;
        } else if (
            !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email)
        ) {
            error["email"] = "Enter a valid email";
            errors++;
        }
        if (this.state.subject === "") {
            error["subject"] = "Enter subject";
            errors++;
        }

        if (this.state.message === "") {
            error["message"] = "Enter message";
            errors++;
        }

        if (errors === 0) {
            this.props.saveContactForm(this.state);
        }


        this.setState({ error: error });
    }
    componentDidMount() {
        //this.props.findStoryDetails(storyId);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.contactForm.message !== this.props.contactForm.message) {
            this.setState({
                success: this.props.contactForm.message,
                name: '',
                email: '',
                subject: '',
                message: '',
            })
        }
    }

    render() {
        //let story = this.props.storyDetails.data;

        return (
            <React.Fragment>

                <div className="anchor-links">
                    <Link onClick={(e) => this.props.changeSection('')}>Home</Link>  /  <Link className="current-page">Contact Us</Link>
                </div>

                <div className="boiler-panel">
                    <div className="boiler-banner">
                        <h5>Contact Us</h5>
                    </div>
                    <div className="boiler-content" dangerouslySetInnerHTML={{__html:  this.props.pageContents['Contact Us'] ? this.props.pageContents['Contact Us'] : ''}}>

                        
                    </div>

                    <div className="form-container contact">
                        
                        <p>Please drop us a mail and we will get back to you</p>

                        <div className="each-input-field">
                            <label>Name</label>
                            <input type="text" name="name" value={this.state.name} onChange={this.handleChange}/>
                            <div
                                className={`error-field${this.state.error.name === '' ? ' hide' : ''}`}>{this.state.error.name}</div>
                        </div>

                        <div className="each-input-field">
                            <label>Email</label>
                            <input type="text" name="email" value={this.state.email} onChange={this.handleChange}/>
                            <div
                                className={`error-field${this.state.error.email === '' ? ' hide' : ''}`}>{this.state.error.email}</div>
                        </div>

                        <div className="each-input-field">
                            <label>Subject</label>
                            <input type="text" name="subject" value={this.state.subject} onChange={this.handleChange}/>
                            <div
                                className={`error-field${this.state.error.subject === '' ? ' hide' : ''}`}>{this.state.error.subject}</div>
                        </div>

                        <div className="each-input-field">
                            <label>Message</label>
                            <textarea name="message" value={this.state.message} onChange={this.handleChange}></textarea>
                            <div
                                className={`error-field${this.state.error.message === '' ? ' hide' : ''}`}>{this.state.error.message}</div>
                        </div>
                        <div className="form-submit-block">
                            <button className="border-btn" onClick={this.onSubmit}>SUBMIT</button>
                        </div>
                        {this.state.success !== '' &&
                            <div className={`error-field`}>{this.state.success}</div>
                        }
                    </div>
                        
                    </div>



            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        loader: actionReducers.getLoader(state),
        contactForm: actionReducers.getContactForm(state),
    }
}
export default connect(mapStateToProps, {
    saveContactForm: ActionCreators.saveContactForm
})(ContactUs);
