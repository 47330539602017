import React, { Component } from "react";
import {Link} from 'react-router-dom';

import moment from "moment";
import * as actionReducers from "../../reducers";
import {connect} from "react-redux";
import * as ActionCreators from "../../actions";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import Share from '../views/Share';
import InfiniteScrollPanel from "../base_levels/InfiniteScrollPanel";

import {IMG_PATH, VIDEO_PATH} from "../../constants";


class Home extends Component {
    constructor(props) {
        super();
        this.state = {
            section: '',
            search: '',
            searchFilterParams:  JSON.stringify({}),
            searchByFilter: false,
            storyToShare: '',
            collectionStories: {data:[],count:0,totalitems:0,page:1},
            bookmarks: [],
            bookmarksIds: []
        }
        this.showShare = this.showShare.bind(this);
        this.addBookmark = this.addBookmark.bind(this);
    }
    showShare(id) {
        this.setState({storyToShare: id});
    }
    goToDetails(id, paidNews, newsLink) {
        localStorage.setItem('box', id);

        localStorage.setItem('refer', '/bookmarks');
        //history.push(newsLink)
        window.location = newsLink;

    }
    addBookmark(story) {
        console.log(story);
        let data = story;
        data.userId = this.props.user.userId
        this.props.addBookmarks(data);
    }
    removeBookmark(story) {
        console.log(story);
        let recordId = this.state.bookmarks.filter((a) => (a['story-id'] === story['Story ID']))[0]['id'];
        let data = story;
        data.userId = this.props.user.userId;
        data.recordId = recordId;

        this.props.deleteBookmarks(data);
    }
    fetchMoreData=()=>{
       // console.log('loadMore')
        //this.getColl('loadMore')
    }
    getColl=(from)=>{

        let params = {bookmarksIds : this.state.bookmarksIds};
        params.page = this.state.collectionStories.page + 1;
        params.dataFrom = from;
        console.log('############# getCol ############');
        console.log(params)
        this.props.findBookmarkedStories(params);

    }






    componentDidUpdate(prevProps, prevState, snapshot) {

console.log(this.props.bookmarks.data)
console.log(prevProps.bookmarks.data)
        if((this.props.bookmarks.data &&
            this.props.bookmarks.data !== prevProps.bookmarks.data &&
            this.props.bookmarks.data.length > 0) ||
            (this.props.bookmarks.data.length === 0 && prevProps.bookmarks.data.length === 1)) {
            let bookmarkIds = this.props.bookmarks.data.map(a => a['story-id']);
            this.setState({
                bookmarks: this.props.bookmarks.data,
                bookmarksIds: bookmarkIds
            });

            this.props.findBookmarkedStories({bookmarkIds: bookmarkIds, dataFrom: 'main'});
            localStorage.setItem('section', '');
            console.log(this.props.bookmarks.data.map(a => a['id']))
            this.props.setIdsInLoop(this.props.bookmarks.data.map(a => a['txt-canonical-url']))
        }

        if(this.props.bookmarkStories.data !== prevProps.bookmarkStories.data) {
            let Data = {};
            if(this.props.bookmarkStories.dataFrom === 'loadMore') {
                console.log('%%%%%%%%%%%%%%');
                console.log(this.props.bookmarkStories)
                Data = {
                    data: this.state.collectionStories.data.concat(this.props.bookmarkStories.data),
                    count: this.props.bookmarkStories.data.length,
                    totalitems: this.props.bookmarkStories.total,
                    page: this.props.bookmarkStories.page
                };
            } else {
                Data = {
                    data: this.props.bookmarkStories.data,
                    count: this.props.bookmarkStories.data.length,
                    totalitems: this.props.bookmarkStories.total,
                    page: this.props.bookmarkStories.page
                };
            }
            this.setState({collectionStories: Data}, () => {
                this.props.setIdsInLoop(Data.data.map(a => a['txt-canonical-url']))
                if(localStorage.getItem('box') && localStorage.getItem('box') !== '') {
                    let storyId = '#story-' + localStorage.getItem('box');
                    if (storyId && document.querySelector(storyId)) {
                        document.querySelector('.main-wrapper').scrollTop = document.querySelector(storyId).offsetTop;
                        localStorage.setItem('box', '');
                        var uri = window.location.toString();
                        if (uri.indexOf("#") > 0) {
                            var clean_uri = uri.substring(0, uri.indexOf("#"));
                            window.history.replaceState({}, document.title, clean_uri);
                        }
                    }
                }
            });
        }

        /*if(prevProps.loggedIn === false && this.props.loggedIn === true) {
            this.props.findBookmarks({userId: this.props.user.userId});
        }*/
        /*if(this.props.bookmarks.data && this.props.bookmarks.data !== prevProps.bookmarks.data) {
            this.setState({
                bookmarks: this.props.bookmarks.data,
                bookmarksIds: this.props.bookmarks.data.map(a => a['story-id'])

            });


        }*/

    }
    componentDidMount() {

        /*if(this.props.scroll !== '') {
            document.querySelector('.main-wrapper').scrollTop = this.props.scroll
        }*/


    }
    render() {
        //let stories = this.props.collectionStories.data;
        let {data,totalitems} = this.state.collectionStories;

        let bookmarksIds = this.state.bookmarksIds;

        console.log(data)
        console.log(bookmarksIds)
        console.log(this.state.collectionStories)
        let boxAds = this.props.ads.box;
        let ud = localStorage.getItem('ud') ? JSON.parse(atob(localStorage.getItem('ud'))) : {};
        let expiresOn = new Date();
        let today = new Date(new Date().setHours(0, 0, 0));
        if(ud['expire on']) {
            let expiry = ud['expire on'].split('-');
            expiresOn = new Date(expiry[0], expiry[1]-1, expiry[2], 0, 0, 0, 0);
        }
        return (
            <React.Fragment>
                {data.length === 0 &&
                <div className="story-placeholder">
                    <p>No stories in the list.</p>
                </div>
                }
                <InfiniteScrollPanel
                    dataLength={parseInt(data.length)}
                    fetchMoreData={this.fetchMoreData}
                    hasMore={totalitems !== data.length}
                    length={parseInt(data.length)}
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="main-wrapper"
                >
                {data.map((story, storyIndx) => {
                    let paidNews = (((story['rd-btn-section'] === 'Investment Insights' || story['rd-btn-section'] === 'Special Coverage') ) && (this.props.loggedIn === false || (this.props.loggedIn === true && expiresOn.getTime() < today.getTime())));
                    //let newsLink = paidNews === true ? "/subscribe?refer=stories" : "article/" + story['id'];
                    let newsLink = paidNews === true ? "/subscribe?refer=stories" : "/stories/" + story['txt-canonical-url'] + '.html';

                    return(
                    <React.Fragment>
                    <div className="each-grid" key={`story-${storyIndx}`} id={`story-${story['Story ID']}`}>

                        <div className="grid-description">
                            <div className="grid-description-header">
                                <p>{moment(new Date(story['date_published_date'])).format('MMM D, YYYY')}</p>
                                <div className={`tag-type ${story['rd-btn-section'].replace(/\s+/g, '-').toLowerCase()}`}>{story['rd-btn-section']}</div>
                            </div>
                            {(story['Lead Image'] && story['Lead Image'] !== '') &&
                            <figure className="grid-image">
                                <Link onClick={(e) => this.goToDetails(story['Story ID'], paidNews, newsLink)}>
                                    <LazyLoadImage
                                        alt={`Lead Image`}
                                        src={IMG_PATH + story['Lead Image']}
                                        effect="opacity"/>
                                </Link>
                            </figure>
                            }
                            <div className="grid-description-wrapper">
                                <div className="grid-description-content">
                                    <h5>
                                        <Link onClick={(e) => this.goToDetails(story['Story ID'], paidNews, newsLink)}>{story['txt-heading']}</Link>
                                    </h5>
                                    <p>

                                        <Link onClick={(e) => this.goToDetails(story['Story ID'], paidNews, newsLink)} dangerouslySetInnerHTML={{__html: story['edtr-short-description']}}></Link>
                                    </p>

                                </div>
                            </div>
                            <div className="grid-description-footer">
                                <div className="grid-option">
                                    {this.props.loggedIn === false &&
                                    <Link href={`${newsLink}`} className="bookmark"></Link>
                                    }
                                    {this.props.loggedIn === true &&
                                    <Link onClick={(e) => this.removeBookmark(story)} className={`bookmark${bookmarksIds.indexOf(story['Story ID']) >= 0 ? ' active' : ''}`}></Link>
                                    }

                                    <div className="share-story">
                                        <button className="share" onClick={(e) => this.showShare(story['id'])}/>
                                        {this.state.storyToShare === story['id'] &&
                                        <Share
                                            url={`${window.location}article/${story['id']}`}
                                            closeShare={this.showShare}
                                        />
                                        }
                                    </div>
                                </div>
                                <Link onClick={(e) => this.goToDetails(story['Story ID'], paidNews, newsLink)}>READ FULL STORY</Link>

                            </div>
                        </div>
                    </div>
                            {(storyIndx + 1) % 6 === 0 && boxAds[parseInt((storyIndx) / 6)] &&
                            <div className="mob-ad hide-lg">
                                {(boxAds[parseInt((storyIndx+1) / 6)-1]['ad_type'] === 'Display' || boxAds[parseInt((storyIndx+1) / 6)-1]['ad_type'] === 'Video') &&
                                <a target="_blank"
                                   href={boxAds[parseInt((storyIndx+1) / 6)-1]['url-display-ad'] || ""} rel="noreferrer"
                                >
                                    {boxAds[parseInt((storyIndx+1) / 6)-1]['ad_type'] === 'Display' &&
                                    <LazyLoadImage
                                        alt={`Ad Image`}
                                        src={IMG_PATH + boxAds[parseInt((storyIndx+1) / 6)-1]['display_ad_material']}
                                        effect="opacity"/>
                                        /*<img src={nodeSrc}/>*/
                                    }
                                    {boxAds[parseInt((storyIndx+1) / 6)-1]['ad_type'] === 'Video' &&
                                    <video src={VIDEO_PATH + boxAds[parseInt((storyIndx+1) / 6)-1]['video_ad_material']} autoPlay={true}/>
                                    }
                                </a>
                                }
                            </div>

                            }
                    </React.Fragment>
                    )
                },)}
                </InfiniteScrollPanel>

            </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        userAccount: actionReducers.getUserAccount(state),
        errorMessage: actionReducers.getErrorMessage(state),
        loader: actionReducers.getLoader(state),
        bookmarkStories: actionReducers.getBookmarkStories(state),
        bookmarks: actionReducers.getBookmarks(state),
    }
}
export default connect(mapStateToProps, {
    signUp: ActionCreators.doSignup,
    userLogin: ActionCreators.userLogin,
    findBookmarkedStories: ActionCreators.findBookmarkedStories,
    addBookmarks: ActionCreators.addBookmarks,
    findBookmarks: ActionCreators.findBookmarks,
    deleteBookmarks: ActionCreators.deleteBookmarks
})(Home);
