import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";


class InfiniteScrollPanel extends React.Component {


    render() {
        /*<LoaderAnimation animation={this.props.dataLength === 0 ? `grow` : `border`}/>*/
        return (
            <InfiniteScroll
                style={{"display": "flex", "flexWrap": "wrap"}}
                className={`no-gutters`}
                dataLength={this.props.dataLength}
                next={this.props.fetchMoreData}
                hasMore={this.props.hasMore}
                loader={this.props.loader}
                scrollableTarget={this.props.scrollableTarget}

            >
                {this.props.children}
            </InfiniteScroll>

        );
    }
}


export default (InfiniteScrollPanel);


