import axios from "axios";
import {API_PATH} from '../constants/index';
import history from "../history";
export  const commonService = {
    sendHttpRequest
}

function sendHttpRequest(URL , data, method, stat) {
    //const API_PATH = '';


    var params = '';
    let apiUrl = URL
    if(method === 'GET' && data) {
        params = data;
    }
    if(stat === false) {
        apiUrl = API_PATH+URL
    }
    let ud = {};
    if (localStorage.getItem("ud") &&
        localStorage.getItem("ud") !== null &&
        localStorage.getItem("ud") !== '' &&
        atob(localStorage.getItem("ud")) !== "") {
        ud = JSON.parse(atob(localStorage.getItem("ud")));
    }

    let statikKey = 'VVRKR2JGcHRaREJPUkZVeFRsUlVUSkdiRnB0WkRCT1JGVXhUbFJGZUU1cVZXaE5la2t3VGxoS2IxcEhXbTVqZWxWNlRrUldaVXBwY0dWTFExbHdTME5WYkZvelRtdGFNMDVyV25rMWJHUXlWbkZoTW1Sb1lUTktiMWxSUFQwPUZlRTVxVldoTmVra3dUbGhLYjFwSFdtNWplbFY2VGtSV1pVcHBjR1ZMUTFsd1MwTlZiRm96VG10YU0wNXJXbmsxYkdReVZuRmhNbVJvWVROS2IxbFJQVDA9PT0';
    let key = (ud && ud.token && ud.token !== '') ? ud.token : statikKey;
    if(key === undefined || key === null || key === '') {
        key = statikKey;
    }

    let param = ""
    // Send an HTTP  request
    return axios({
        method: method,
        url: apiUrl,
        data: data,
        params : params,
        async:false,
        headers : {'Content-Type' : 'application/json',"X_API_KEY": key}
    }).then(function(res){
        //console.log("success");
        /*if(res.data.response === 'failure' || Object.keys(res.data.response).length === 0) {
            return undefined;
        }*/
        //console.log(res)
        return res;

    }).catch(function(error){
        //console.log(error.response)
        if (error.response) {
            if (error.response.status === 401 && (
                error.response.data.error.message === "Unauthorized" ||
                error.response.data.error.message === "token expired." ||
                error.response.data.error.message === "token not valid.")
            ) {
                param = {
                    code: 401
                }
                return param
            }

        }



    });


}
