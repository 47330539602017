import { combineReducers } from 'redux';


const Loader = (state = {status:false, element:''}, action) => {
    switch(action.type){
        case "UPDATE_LOADER":
            return {
                ...state,
                status: action.status,
                element: action.element
            }

        default:
            return state;
    }
}


const CommonReducer = combineReducers({
    Loader
})

export default CommonReducer;
export const getLoader = state => state.CommonReducer.Loader;
