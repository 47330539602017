import React, { Component } from "react";


import * as actionReducers from "../../reducers";
import {connect} from "react-redux";
import * as ActionCreators from "../../actions";
import {getUrlSegment} from "../../utilities/CustomFunctions";
import {IMG_PATH, VIDEO_PATH} from "../../constants/index";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import Share from "../views/Share";

import SwipeItem from "../views/SwipeItem";



class Article extends Component {
    constructor(props) {
        super();
        this.state = {
            flag: false,
            storyFlag: false,
            adFlag: false,
            storyToShare:'',
            loopIds: [],
            bookmarks: [],
            bookmarksIds: [],
            storyDetails: {},
            next: 0,
            prev: 0,
            page: 1

        };
        this.myRef = React.createRef()
        this.showShare = this.showShare.bind(this);
        this.setAdPosition = this.setAdPosition.bind(this);
        this.addBookmark = this.addBookmark.bind(this);
        this.removeBookmark = this.removeBookmark.bind(this);
        this.bookmarkAction = this.bookmarkAction.bind(this);
        this.getArticle = this.getArticle.bind(this);
        this.checkValidUser = this.checkValidUser.bind(this);

    }
    showShare(id) {
        this.setState({storyToShare: id});
    }
    checkValidUser() {

        document.getElementById('dummy-story').innerHTML = "";
        let storyDetails = {};

        storyDetails = this.state.storyDetails;
        localStorage.setItem('box', storyDetails['Story ID']);


        let flags = storyDetails['flag'] ? storyDetails['flag'] : [];
        let ud = localStorage.getItem('ud') ? JSON.parse(atob(localStorage.getItem('ud'))) : {};
        let expiresOn = new Date();
        let today = new Date(new Date().setHours(0, 0, 0));
        if(ud['expire on']) {
            let expiry = ud['expire on'].split('-');
            expiresOn = new Date(expiry[0], expiry[1]-1, expiry[2], 0, 0, 0, 0);
        }
        let loggedIn = ud.userId && ud.userId !== '' ? true : false;

        /*console.log('### checkValidUser ...')
        console.log(flags)
        console.log(storyDetails['rd-btn-section'])
        console.log(flags.indexOf('Special Free') < 0)
        console.log(loggedIn)
        console.log(expiresOn.getTime())
        console.log(today.getTime())
        console.log(expiresOn.getTime() < today.getTime())
        console.log(
            storyDetails['rd-btn-section'] &&
            ((storyDetails['rd-btn-section'][0] === 'Investment Insights' ||
                storyDetails['rd-btn-section'][0] === 'Special Coverage') &&
                (flags.indexOf('Special Free') < 0)) &&
            (loggedIn === false || (loggedIn === true && expiresOn.getTime() < today.getTime())));*/
        if(
            storyDetails['rd-btn-section'] &&
            ((storyDetails['rd-btn-section'][0] === 'Investment Insights' ||
                storyDetails['rd-btn-section'][0] === 'Special Coverage') &&
                (flags.indexOf('Special Free') < 0)) &&
            (loggedIn === false || (loggedIn === true && expiresOn.getTime() < today.getTime()))) {
            if(loggedIn === true && expiresOn.getTime() < today.getTime()) {
                window.location = "/my-account?refer=stories";
            } else {
                window.location = "/subscribe?refer=stories";
            }
        } else {

        }
    }
    InsertAd() {

        document.getElementById('dummy-story').innerHTML = "";
        let storyDetails = {};

        storyDetails = this.state.storyDetails;
        localStorage.setItem('box', storyDetails['Story ID']);


        let flags = storyDetails['flag'] ? storyDetails['flag'] : [];
        let ud = localStorage.getItem('ud') ? JSON.parse(atob(localStorage.getItem('ud'))) : {};
        let expiresOn = new Date();
        let today = new Date(new Date().setHours(0, 0, 0));
        if(ud['expire on']) {
            let expiry = ud['expire on'].split('-');
            expiresOn = new Date(expiry[0], expiry[1]-1, expiry[2], 0, 0, 0, 0);
        }
        let loggedIn = ud.userId && ud.userId !== '' ? true : false;
        /*console.log(flags)
        console.log(storyDetails['rd-btn-section'])
        console.log(flags.indexOf('Special Free') < 0)
        console.log(loggedIn)
        console.log(expiresOn.getTime())
        console.log(today.getTime())
        console.log(expiresOn.getTime() < today.getTime())
        console.log(
            storyDetails['rd-btn-section'] &&
            ((storyDetails['rd-btn-section'][0] === 'Investment Insights' ||
                storyDetails['rd-btn-section'][0] === 'Special Coverage') &&
                (flags.indexOf('Special Free') < 0)) &&
            (loggedIn === false || (loggedIn === true && expiresOn.getTime() < today.getTime())));*/
        if(
            storyDetails['rd-btn-section'] &&
            ((storyDetails['rd-btn-section'][0] === 'Investment Insights' ||
                storyDetails['rd-btn-section'][0] === 'Special Coverage') &&
                (flags.indexOf('Special Free') < 0)) &&
            (loggedIn === false || (loggedIn === true && expiresOn.getTime() < today.getTime()))) {
            if(loggedIn === true && expiresOn.getTime() < today.getTime()) {
                window.location = "/my-account?refer=stories";
            } else {
                window.location = "/subscribe?refer=stories";
            }
        } else {
            this.setState({flag: false});
            let ad = this.props.ads.solo[0] || {};
            //console.log(ad)



            let story;
            if(window.mode && window.mode === 'publish') {
                story = this.state.storyDetails;
            } else {
                story = this.props.storyDetails.data;
            }
            let dummy = document.getElementById('dummy-story');
            let node;
            let parentNode = document.createElement("DIV");
            parentNode.className = 'advertisement-cntnr';
            let link = document.createElement("a");
            link.href = this.props.ads.solo[0]['url-display-ad'];
            link.target = "_blank";
            dummy.innerHTML = story['edtr-full-story'] ? decodeURIComponent(escape((atob(story['edtr-full-story'][0])))).replace(/<p><br><\/p>/gm, '').replace(/<p><\/p>/gm, '') : '';
            if (ad && ad.ad_type === 'Display') {
                node = document.createElement("IMG");
                node.id = 'ads-image';
                node.src = IMG_PATH + this.props.ads.solo[0]['display_ad_material'];
            }
            if (ad && ad.ad_type === 'Video') {
                node = document.createElement("VIDEO");
                node.id = 'ads-image';
                node.src = VIDEO_PATH + this.props.ads.solo[0]['video_ad_material'];
                node.autoPlay = true;
            }
            link.appendChild(node);
            parentNode.appendChild(link);
            //var child = dummy.children[2];
            let secondPara = dummy.querySelectorAll("p")
            if(story['edtr-full-story'] && story['edtr-full-story'] !== '') {
                dummy.insertBefore(parentNode, secondPara[3]);
            }
            this.setState({flag: true});
            this.myRef.current.scrollTo(0, 0);
        }
    }
    setAdPosition(id) {
        let loopIds = JSON.parse(localStorage.getItem('loopIds') || JSON.stringify([]));
        let adPosition = loopIds.indexOf(id);;
        localStorage.setItem('adPosition', adPosition);
        /*history.push(id + '.html' );
        if(id) {
            this.props.findStoryDetails(id);
        }*/
        window.location = id + '.html' ;
    }
    bookmarkAction(story) {
        let indx = this.state.bookmarksIds.indexOf(story['Story ID'][0])
        if(indx < 0) {
            this.addBookmark(story);
        } else {
            this.removeBookmark(story)
        }
    }
    addBookmark(story) {
        //console.log(story);
        let data = story;
        data.uniqueid = story['Story ID'][0];
        data.userId = this.props.user.userId;
        data.heading = story['txt-heading'][0];
        data.attachment = story['Lead Image'][0];
        this.props.addBookmarks(data);
    }
    removeBookmark(story) {
        //console.log(story);
        //console.log(this.state.bookmarks);
        let recordId = this.state.bookmarks.filter((a) => (a['story-id'] === story['Story ID'][0]))[0]['id'];
        let data = story;
        data.userId = this.props.user.userId;
        data.recordId = recordId;

        this.props.deleteBookmarks(data);
    }
    getArticle() {
        let storyId = getUrlSegment(2) || "";
        if(storyId) {
            this.props.findStoryDetails(storyId);
        }
    }
    componentDidMount() {
        if(window.mode && window.mode === 'publish') {

        } else {
            this.getArticle();
            window.addEventListener('popstate', this.getArticle);
        }
        let loopIds = JSON.parse(localStorage.getItem('loopIds') !== null ? localStorage.getItem('loopIds') : JSON.stringify([]));
        if(loopIds.length === 0){
            this.props.findStories({section: ''});
        }

    }
    componentWillUnmount() {
        window.removeEventListener('popstate', this.loadUserData);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let obj = this;
        if(!window.mode || window.mode !== 'publish') {
            if (prevProps.storyDetails.data !== this.props.storyDetails.data) {
                this.setState({storyDetails: this.props.storyDetails.data, storyFlag: true}, () => {
                    //console.log('++++++++++adFlag' + this.state.adFlag)
                    obj.checkValidUser();
                    if (this.state.adFlag === true) {
                        obj.InsertAd();
                    }
                });
            }

            if (this.state.adFlag === false && this.props.ads.solo && this.props.ads.solo.length > 0) {
                this.setState({adFlag: true}, () => {
                    //console.log('++++++++++storyFlag' + this.state.storyFlag)
                    if (this.state.storyFlag === true) {
                        obj.InsertAd();
                    }
                });
            }
        }
        if(window.mode && window.mode === 'publish') {
            let storyDetails = {
                "Lead Image": [window.storyImage],
                "Story ID": [window.storyId],
                "edtr-full-story": [window.storyFullStory],
                "edtr-short-description": [window.storyShortDesc],
                "flag": [window.storyFlag],
                "rd-btn-section": [window.storySection],
                "txt-heading": [window.storyTitle],
                "canonicalurl": window.storyUrl,
                "publishdate": window.storyDate,
            };
            localStorage.setItem('box', storyDetails['Story ID']);
            //console.log(this.state.storyDetails['Story ID'])
            //console.log(prevState.storyDetails['Story ID'])
            //console.log(this.props.storyId)
            if(!prevState.storyDetails['Story ID'] || prevState.storyDetails['Story ID'][0] !== this.props.storyId) {
                this.setState({storyDetails: storyDetails, storyFlag: true}, () => {
                    //console.log('++++++++++adFlag' + this.state.adFlag)
                    obj.checkValidUser();
                    if (this.state.adFlag === true) {
                        obj.InsertAd();
                    }
                });
            }
            if (this.state.adFlag === false && this.props.ads.solo && this.props.ads.solo.length > 0) {
                this.setState({adFlag: true}, () => {
                    //console.log('++++++++++storyFlag' + this.state.storyFlag)

                        obj.InsertAd();

                });
            }
            let loopIds = JSON.parse(localStorage.getItem('loopIds') || JSON.stringify([]));

            let pos = loopIds.indexOf(storyDetails['canonicalurl']);
            //console.log(loopIds)
            //console.log(storyDetails['canonicalurl'])
            //console.log(pos)

            let next = pos >= 0 && pos < loopIds.length-1 ? loopIds[pos + 1] : loopIds[0];
            let prev = pos > 0 ? loopIds[pos - 1] : loopIds[loopIds.length - 1];
            if(this.state.next !== next ) {
                this.setState({next: next});
                if(pos > loopIds.length - 5) {
                    let page = parseInt(localStorage.getItem('page')) + 1;

                    this.props.findStories({allstories: false, page: page});
                }
            }
            if(this.state.prev !== prev ) {
                this.setState({prev: prev});
            }

        }
        if((this.props.stories && this.props.stories.data !== prevProps.stories.data) ||
            (this.props.stories.data.length > 0 && this.props.stories.page !== this.state.page)) {
            let loopIds = this.props.stories.data.map(a => a.canonicalurl)
            let _ids = JSON.parse(localStorage.getItem('loopIds'));
            _ids = [...new Set(_ids.concat(loopIds))];
            localStorage.setItem('loopIds', JSON.stringify(_ids));
            let page = localStorage.getItem('page') ? parseInt(localStorage.getItem('page')) : 1;
            //console.log(this.props.stories.page);
            localStorage.setItem('page', this.props.stories.page);
            this.setState({page: page});
            let collectionStories = sessionStorage.getItem('collectionStories') ? JSON.parse(sessionStorage.getItem('collectionStories')) : {};
            let Data = {
                data: collectionStories.data ? collectionStories.data.concat(this.props.stories.data) : this.props.stories.data,
                count: this.props.stories.data.length,
                totalitems: this.props.stories.total,
                page: this.props.stories.page
            }

            sessionStorage.setItem('collectionStories', JSON.stringify(Data))
        }
        if(this.props.bookmarks.data && this.props.bookmarks.data !== prevProps.bookmarks.data) {
            this.setState({
                bookmarks: this.props.bookmarks.data,
                bookmarksIds: this.props.bookmarks.data.map(a => a['story-id'])
            });
        }
    }

    render() {
        let story = this.state.storyDetails;
        //console.log(story)
        /*console.log(story['rd-btn-section'][0])
        console.log(story['flag'])
        console.log((
            (story['rd-btn-section'] &&
            (story['rd-btn-section'][0] === 'Investment Insights' ||
                story['rd-btn-section'][0] === 'Special Coverage') &&
                (story['flag'].indexOf('Special Free') < 0)) &&
            this.props.loggedIn === false))*/

        let lastRefer = localStorage.getItem('refer') || "/";
        /*if(
            story['rd-btn-section'] &&
            ((story['rd-btn-section'][0] === 'Investment Insights' ||
                story['rd-btn-section'][0] === 'Special Coverage') &&
                (story['flag'].indexOf('Special Free') < 0)) &&
            this.props.loggedIn === false) {
            return <Redirect to="/subscribe?refer=stories" />
        }*/
        let shortStory = story['edtr-short-description'] ? decodeURIComponent(escape((atob(story['edtr-short-description'][0])))) : '';
        let fullStory = story['edtr-full-story'] ? decodeURIComponent(escape((atob(story['edtr-full-story'][0])))).replace(/<p><br><\/p>/gm, '').replace(/<p><\/p>/gm, '') : '';
        if(this.state.flag === true) {
            fullStory = document.getElementById('dummy-story') ? document.getElementById('dummy-story').innerHTML : '';
        }

        //let storyDescription = shortStory + fullStory;
        let section = story['rd-btn-section'] ? story['rd-btn-section'][0].replace(/\s+/g, '-').toLowerCase() : '';



        let next = this.state.next;
        let prev = this.state.prev;

        //let bookmarks = this.state.bookmarks;
        let bookmarksIds = this.state.bookmarksIds;
        //console.log(next + '-' + prev)
        let ud = localStorage.getItem('ud') ? JSON.parse(atob(localStorage.getItem('ud'))) : {};
        let loggedIn = ud.userId && ud.userId !== '' ? true : false;
        return (
            <React.Fragment>

                <div className="anchor-links">
                    <a onClick={(e) => this.props.changeSection('')}>Home</a>  /  <a onClick={(e) => this.props.changeSection(story['rd-btn-section'][0])}>{story['rd-btn-section'] ? story['rd-btn-section'][0] : ''}</a>  /  <a className="current-page">{story['txt-heading'] ? decodeURIComponent(escape((atob(story['txt-heading'][0])))).substring(0, 30) + (story['txt-heading'][0].length > 30 ? '...' : '') : ''}</a>
                </div>
                <div className="dummy-story hide" id="dummy-story" style={{display: "block"}}></div>
                <SwipeItem setAdPosition={this.setAdPosition} next={next} prev={prev}>
                    {story['Story ID'] &&
                    <article className="article-details-wrapper" ref={this.myRef}>
                        {(story['Lead Image'] && story['Lead Image'][0] !== '') &&
                        <figure className="article-image">
                            {/*<img src={story['Lead Image'] ? IMG_PATH + story['Lead Image'][0] : ''} />*/}
                            <LazyLoadImage
                                alt={`Story Image`}
                                src={story['Lead Image'] ? story['Lead Image'][0] : ''}
                                effect="opacity"/>
                        </figure>
                        }
                        <div className="article-details">

                            <div className="article-header">
                                <p>{story['publishdate']}</p>
                                <div className={`tag-type news ${section}`}>{story['rd-btn-section'] ? story['rd-btn-section'][0] : ''}</div>
                            </div>

                            <div className="article-content">

                               {/* <h3>{story['txt-heading'] ? story['txt-heading'][0] : ''}</h3>*/}
                                <h3 dangerouslySetInnerHTML={{__html: story['txt-heading'] ? decodeURIComponent(escape((atob(story['txt-heading'][0])))) : ''}}></h3>

                                <div className="article-content-details">
                                    <div className="short-story" dangerouslySetInnerHTML={{__html: shortStory}}></div>
                                    <div className="full-story" dangerouslySetInnerHTML={{__html: fullStory}}></div>
                                </div>

                                <div className="article-extra-block">
                                    <div className="grid-option">
                                        {loggedIn === false &&
                                        <a href={`/subscribe?refer=stories`} className="bookmark"></a>
                                        }
                                        {loggedIn === true &&
                                        <a href="Javascript:void(0)" onClick={(e) => this.bookmarkAction(story)}
                                        className={`bookmark${bookmarksIds.indexOf(story['Story ID'] ? story['Story ID'][0] : '') >= 0 ? ' active' : ''}`}></a>
                                        }
                                        <div className="share-story">
                                            <button className="share" onClick={(e) => this.showShare(story['_id'])}/>
                                            {this.state.storyToShare === story['_id'] &&
                                            <Share
                                                url={`${window.location}`}
                                                title={decodeURIComponent(escape((atob(story['txt-heading'][0])))) }
                                                description={shortStory}
                                                closeShare={this.showShare}
                                            />
                                            }
                                        </div>
                                    </div>
                                </div>

                                {story['Story ID'] &&
                                <div className="close-block">
                                    <a className="close-story"
                                       onClick={(e) => this.props.backToNews(lastRefer)}>Close</a>

                                    <div className="story-next">
                                        <a class="prev-story" title="Previous" href={`Javascript:void(0)`}
                                           onClick={(e) => this.setAdPosition(prev)}></a>
                                        <a class="next-story" title="Next" href={`Javascript:void(0)`}
                                           onClick={(e) => this.setAdPosition(next)}></a>
                                    </div>
                                </div>
                                }
                            </div>

                        </div>
                    </article>
                    }
                </SwipeItem>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        loader: actionReducers.getLoader(state),
        storyDetails: actionReducers.getStoryDetails(state),
        stories: actionReducers.getStories(state),
        bookmarks: actionReducers.getBookmarks(state),
    }
}
export default connect(mapStateToProps, {
    findStoryDetails: ActionCreators.findStoryDetails,
    findStories: ActionCreators.findStories,
    addBookmarks: ActionCreators.addBookmarks,
    findBookmarks: ActionCreators.findBookmarks,
    deleteBookmarks: ActionCreators.deleteBookmarks
})(Article);
