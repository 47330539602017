export function getUrlSegment(segment) {
    var url = window.location.pathname.split('/');
    return url[segment];
}
export function encode(str) {
    let encoded = "";
    let i;
    str = btoa(str);
    str = btoa(str);
    for (i=0; i<str.length;i++) {
        let a = str.charCodeAt(i);
        let b = a ^ 10; // bitwise XOR with any number, e.g. 123
        encoded = encoded+String.fromCharCode(b);
    }
    encoded = btoa(encoded);
    return encoded;
}
