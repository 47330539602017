import React from "react";

class SwipeItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            beingTouched: false,
            touchStart: 0,
            touchStartY: 0,
            touchEnd: 0,
            touchEndY: 0
        };
        this.swipeItem = React.createRef()
    }

    handleStart(clientX) {
        this.setState({
            touchStart: clientX,
            touchEnd: clientX,
            beingTouched: true
        });
    }

    handleMove(clientX) {
        if (this.state.beingTouched) {
            this.setState({
                touchEnd: clientX
            });
        }
    }

    handleEnd() {
        if (this.state.beingTouched) {
            console.log(this.swipeItem.current.offsetWidth)
            console.log(this.state.touchStart + '-' + this.state.touchEnd)
            console.log(this.state.touchStart - this.state.touchEnd)
            let movementP = ((this.state.touchStart - this.state.touchEnd) / this.swipeItem.current.offsetWidth) * 100;
            console.log(movementP);
            if ((this.state.touchStart > this.state.touchEnd) && (movementP > 75)) {
                this.props.setAdPosition(this.props.next)
            }
            if ((this.state.touchStart < this.state.touchEnd) && (-1 * movementP > 75)) {
                this.props.setAdPosition(this.props.prev)
            }
            this.setState({
                beingTouched: false,
                touchStart: 0,
                touchEnd: 0
            });
        }
    }

    handleTouchStart(touchStartEvent) {
        touchStartEvent.preventDefault();
        this.handleStart(touchStartEvent.targetTouches[0].clientX);
    }

    handleTouchMove(touchMoveEvent) {
        this.handleMove(touchMoveEvent.targetTouches[0].clientX);
    }

    handleTouchEnd() {
        this.handleEnd();
    }

    handleMouseDown(mouseDownEvent) {
        mouseDownEvent.preventDefault();
        this.handleStart(mouseDownEvent.clientX);
    }

    handleMouseMove(mouseMoveEvent) {
        this.handleMove(mouseMoveEvent.clientX);
    }

    handleMouseUp() {
        this.handleEnd();
    }

    handleMouseLeave() {
        this.handleMouseUp();
    }

    render() {
        return (
            <div
                className="swipe-item"
                onTouchStart={touchStartEvent => this.handleTouchStart(touchStartEvent)}
                onTouchMove={touchMoveEvent => this.handleTouchMove(touchMoveEvent)}
                onTouchEnd={() => this.handleTouchEnd()}
                // The following event handlers are for mouse compatibility:
                onMouseDown={mouseDownEvent => this.handleMouseDown(mouseDownEvent)}
                onMouseMove={mouseMoveEvent => this.handleMouseMove(mouseMoveEvent)}
                onMouseUp={() => this.handleMouseUp()}
                onMouseLeave={() => this.handleMouseLeave()}
                ref={this.swipeItem}
            >

                    {this.props.children}

            </div>
        );
    }
}
export default SwipeItem;
