import React, { Component } from "react";
import {Link} from 'react-router-dom';
import * as actionReducers from "../../reducers";
import {connect} from "react-redux";
import * as ActionCreators from "../../actions";


class PrivacyPolicy extends Component {

    componentDidMount() {
        //this.props.findStoryDetails(storyId);
    }
    render() {
        //let story = this.props.storyDetails.data;

        return (
            <React.Fragment>

                <div className="anchor-links">
                    <Link onClick={(e) => this.props.changeSection('')}>Home</Link>  /  <Link className="current-page">Terms</Link>
                </div>

                <div class="boiler-panel">
                    <div class="boiler-banner">
                        <h5>Privacy Policy</h5>
                    </div>
                    <div className="boiler-content"
                         dangerouslySetInnerHTML={{__html: this.props.pageContents['Privacy Policy'] ? this.props.pageContents['Privacy Policy'].replace(/<p><br><\/p>/gm, '').replace(/<p>&nbsp;<\/p>/gm, '') : ''}}>
                    </div>
                        
                    </div>



            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        loader: actionReducers.getLoader(state),
        storyDetails: actionReducers.getStoryDetails(state),
    }
}
export default connect(mapStateToProps, {
    findStoryDetails: ActionCreators.findStoryDetails
})(PrivacyPolicy);
