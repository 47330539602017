import React, { Component } from "react";
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import axios from "axios";
import {
    GOOGLE_KEY,
    FB_KEY,
    RAZORPAY_KEY_ID,
    RAZORPAY_KEY_SECRET,
    HOME_PAGE
} from "../../constants/index";
import {Redirect} from 'react-router-dom'

import moment from "moment";
import * as actionReducers from "../../reducers";
import {connect} from "react-redux";
import * as ActionCreators from "../../actions";
import {transactionsAPILog} from "../../actions";

class Plan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            mobile: '',
            profession: '',
            dob: '',
            password: '',
            confirmPassword: '',
            terms: false,
            user_category: 'Non Bsynapse User',
            error: {
                name: "",
                email: "",
                mobile: "",
                profession: "",
                dob: "",
                password: '',
                confirmPassword: ''
            },
            checkmailexists: false,
            otpConfirmed: false,
            otpSent: false,
            otpResendTimer: 0,
            otpResendButton: false,
            otp: '',
            loginType: 'internal',
            changeNumber: false,
            paymentStatus: '',
            orderId: '',
            paymentId: '',
            paymentFailure: {},
            selectedPlan: {},
            paymentInfo: {},
            customerPlan: {},
            countryCode: '',
            countryName: '',
            proceedToPay: false

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.showNext = this.showNext.bind(this);
        this.paymentDetails = this.paymentDetails.bind(this);
        this.showPrevious = this.showPrevious.bind(this);
        this.signupGoogle = this.signupGoogle.bind(this);
        this.signupFB = this.signupFB.bind(this);
        this.resetNumber = this.resetNumber.bind(this);
        this.gotToSubscribe = this.gotToSubscribe.bind(this);

        this.t = 0;
    }

    handleChange(e) {
        let value = e.target.value;
        if(e.target.name === 'mobile') {
            value = value.replace(this.state.countryCode, '');
        }
        if(e.target.name === 'email') {
            value = value.toLowerCase();
        }
        this.setState({[e.target.name]: value});
    }



    paymentDetails(customerPlan, paymentId) {
        localStorage.setItem('flag', 'up')
        //.log(customerPlan);
        let plan = JSON.parse(localStorage.getItem('plan'));
        //console.log(plan);
        let refer = plan.refer;//new URL(window.location.href).searchParams.get("refer");
        let ud = localStorage.getItem('ud') ? JSON.parse(atob(localStorage.getItem('ud'))) : {};
        if (refer === 'renew' && ud.userId) {

            //let startDate = JSON.parse(atob(localStorage.getItem('ud')))['expire on'];
            //startDate = moment(startDate).add(1, "days").format('YYYY-MM-DD');
            let startDate = JSON.parse(atob(localStorage.getItem('ud')))['expire on'];
            let today = moment().toDate();
            if(moment(startDate).isBefore(moment(), "day")) {
                startDate = moment(today).format('YYYY-MM-DD');
            } else {
                startDate = moment(startDate).add(1, "days").format('YYYY-MM-DD');
            }
            let endDate = moment(startDate).add(customerPlan.selectedPlan['number_months'], 'M').format('YYYY-MM-DD');
            endDate = moment(endDate).subtract(1, "days").format('YYYY-MM-DD');
            //console.log(startDate)
            //console.log(endDate)
            this.props.renewSubscriber({
                name: customerPlan.name,
                email: customerPlan.email,
                mobile: customerPlan.mobile,
                profession: customerPlan.profession,
                password: customerPlan.password,
                loginType: customerPlan.loginType,
                plan: customerPlan.selectedPlan,
                userId: ud['userId'],
                startDate: ud['start date'],
                referenceID: ud['referenceID'],
                subscriberId: ud['subscriber_ id'],
                renewalDate: startDate,
                expireDate: endDate,
                paymentId: paymentId
            });

        } else if (refer === 'upgrade' && ud.userId) {
            //let startDate = JSON.parse(atob(localStorage.getItem('ud')))['expire on'];
            //startDate = moment(startDate).add(1, "days").format('YYYY-MM-DD');
            let startDate = JSON.parse(atob(localStorage.getItem('ud')))['expire on'];
            let today = moment().toDate();
            if(moment(startDate).isBefore(moment(), "day")) {
                startDate = moment(today).format('YYYY-MM-DD');
            } else {
                startDate = moment(startDate).add(1, "days").format('YYYY-MM-DD');
            }
            let endDate = moment(startDate).add(customerPlan.selectedPlan['number_months'], 'M').format('YYYY-MM-DD');
            endDate = moment(endDate).subtract(1, "days").format('YYYY-MM-DD');
            //console.log(startDate)
            //console.log(endDate)
            this.props.renewSubscriber({
                name: customerPlan.name,
                email: customerPlan.email,
                mobile: customerPlan.mobile,
                profession: customerPlan.profession,
                password: customerPlan.password,
                loginType: customerPlan.loginType,
                plan: customerPlan.selectedPlan,
                userId: ud['userId'],
                startDate: ud['start date'],
                referenceID: ud['referenceID'],
                subscriberId: ud['subscriber_ id'],
                renewalDate: startDate,
                expireDate: endDate,
                orderId: customerPlan.orderId,
                paymentId: paymentId
            });

        } else {
            let startDate = moment().format('YYYY-MM-DD');
            let endDate = moment().add(customerPlan.selectedPlan['number_months'], 'M').format('YYYY-MM-DD');
            endDate = moment(endDate).subtract(1, "days").format('YYYY-MM-DD');
            //console.log(startDate)
            //console.log(endDate)


            this.props.addSubscriber({
                name: customerPlan.name,
                email: customerPlan.email,
                mobile: customerPlan.mobile,
                dob: customerPlan.dob,
                profession: customerPlan.profession,
                password: customerPlan.password,
                loginType: customerPlan.loginType,
                plan: customerPlan.selectedPlan,
                startDate: startDate,
                endDate: endDate,
                paymentId: paymentId,
                orderId: customerPlan.orderId,
                user_category: customerPlan.user_category
            });

        }
    }

    handleDateChange(date, name) {
        this.setState({
            [name]: date
        });
    }

    showPrevious(id) {
        this.props.setPlanTab(id);
    }

    signupGoogle(e) {
        this.setState({checkmailexists: true});
        this.props.signupGoogle(e)
    }

    signupFB(e) {
        this.setState({checkmailexists: true});
        this.props.signupFb(e)
    }

    showNext(id) {
        let errors = 0;
        let error = {
            name: "",
            email: "",
            mobile: "",
            profession: "",
            dob: "",
            password: '',
            confirmPassword: '',

        };
        if (id === 1) {
            this.setState({checkmailexists: false});
            if (this.state.name === "") {
                error["name"] = "Enter name";
                errors++;
            }
            if (this.state.email === "") {
                error["email"] = "Enter email";
                errors++;
            } else if (
                !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email)
            ) {
                error["email"] = "Enter a valid email";
                errors++;
            }
            if (this.state.mobile === "") {
                error["mobile"] = "Enter mobile";
                errors++;
            }

            if (this.state.profession === "") {
                error["profession"] = "Enter profession";
                errors++;
            }
            if (this.state.dob === "") {
                error["dob"] = "Enter date of birth";
                errors++;
            }
            if(this.state.user_category !== 'Bsynapse User') {
                if (this.state.password === "") {
                    error["password"] = "Enter password";
                    errors++;
                }
                if (this.state.confirmPassword === "") {
                    error["confirmPassword"] = "Re-enter password";
                    errors++;
                } else if (this.state.password !== this.state.confirmPassword) {
                    error["confirmPassword"] = "Those passwords didn’t match. Try again.";
                    errors++;
                }
            }
            if (errors === 0) {
                let mobile = this.state.mobile.replace(this.state.countryCode, '');

                this.setState({
                    checkmailexists: true,
                    mobile: this.state.countryCode + mobile
                });
                this.props.checkIfUserExists(this.state);
            }
        }
        if (id === 2) {
            if (this.state.terms !== "on") {
                error["terms"] = "Please agree the terms";
                errors++;
            }
            if (errors === 0) {
                this.props.acceptTerms();
            }
        }

        this.setState({error: error});
    }

    proceedToPay() {
        this.setState({
            proceedToPay: false,
            mobile: this.state.countryCode + this.state.mobile.replace(this.state.countryCode, '')
        });
        let mobile = this.state.countryCode + this.state.mobile.replace(this.state.countryCode, '');
        let errors = 0;
        let error = {
            mobile: ""
        };
        if ( this.state.mobile.replace(this.state.countryCode, '') === "") {
            error["mobile"] = "Enter mobile number to verify";
            errors++;
        }
        if (errors === 0) {
            this.setState({proceedToPay: true});
        }
        this.setState({error: error});
    }
    getOTP() {
        this.setState({
            changeNumber: false,
            otpSent: false,
            otpResendButton: false,
            otpResendTimer: 0,
            mobile: this.state.countryCode + this.state.mobile.replace(this.state.countryCode, '')
        });
        let mobile = this.state.countryCode + this.state.mobile.replace(this.state.countryCode, '');
        let errors = 0;
        let error = {
            mobile: ""
        };
        if (this.state.mobile === "") {
            error["mobile"] = "Enter mobile number to verify";
            errors++;
        }
        if (errors === 0) {
            this.props.sendOTP({mobile: mobile});
            this.setState({otpSent: true});
            let counter = 180;
            this.setState({otpResendTimer: counter});
            //let resend = false;
            let obj = this;
            clearInterval(obj.t);
            this.t = setInterval(function () {
                //console.log(counter);
                counter--
                obj.setState({otpResendTimer: counter});
                if (counter === 0) {
                    obj.setState({otpResendButton: true});
                    clearInterval(obj.t)
                }
            }, 1000);
        }
        this.setState({error: error});
    }

    resendOTP() {
        this.setState({
            changeNumber: false,
            otpSent: false,
            otpResendButton: false,
            otpResendTimer: 0
        })
        let errors = 0;
        let error = {
            mobile: ""
        };
        if (this.state.mobile === "") {
            error["mobile"] = "Enter mobile number to verify";
            errors++;
        }
        if (errors === 0) {
            this.props.sendOTP({mobile: this.state.mobile});
            /*this.setState({
                otp: '',
                otpSent: true,
                otpConfirmed: false
            });
            let counter = 180;
            this.setState({otpResendTimer: counter});
            let resend = false;
            let obj = this;
            clearInterval(obj.t);
            this.t = setInterval(function () {
                //console.log(counter);
                counter--
                obj.setState({otpResendTimer: counter});
                if (counter === 0) {
                    obj.setState({otpResendButton: true});
                    clearInterval(obj.t)
                }
            }, 1000);*/
        }
        this.setState({error: error});
    }

    verifyOTP() {
        let errors = 0;
        let error = {
            otp: ""
        };
        if (this.state.otp === "") {
            error["otp"] = "Enter OTP to verify";
            errors++;
        }
        if (errors === 0) {
            this.props.verifyOTP({
                mobile: this.state.mobile,
                otp: this.state.otp
            });
            this.setState({otpConfirmed: true});
        }
        this.setState({error: error});
    }

    resetNumber() {
        this.setState({changeNumber: true});
    }

    gotToSubscribe() {
        let customerPlan = JSON.parse(localStorage.getItem('plan'));
        let ud = localStorage.getItem('ud') ? JSON.parse(atob(localStorage.getItem('ud'))) : {};
        if ((customerPlan.refer === 'renew' || customerPlan.refer === 'upgrade') && ud.userId) {
            window.location = '/my-account';
        } else {
            window.location = '/subscribe';
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        //console.log(prevState.loginType);
        //console.log(this.props.signupData.logintype);
        let orderCallback = window.order ? window.order : {}
        console.log(this.props)



        if (prevState.email !== this.props.signupData.email && this.props.signupData.email ||
            (prevState.loginType !== this.props.signupData.logintype && this.props.signupData.logintype)) {
            //console.log("1--------------");
            this.setState({
                name: this.props.signupData.name || '',
                email: this.props.signupData.email || '',
                mobile: this.props.signupData.mobile || '',
                password: this.props.signupData.password || '',
                loginType: this.props.signupData.logintype || 'internal',
                profession: this.props.signupData.profession || '',
                dob: this.props.signupData.dob || '',

            });
        }
        if (prevProps.subscriber.data !== this.props.subscriber.data &&
            this.props.subscriber.success === false && !this.props.subscriber.data.userId) {
            //console.log("1--------------");
            this.props.toggleModal(
                true,
                'Subscription',
                this.props.subscriber.message,
                this.props.toggleModal,
                false,
                this.props.toggleModal,
                'md'
            );
            this.props.setPlanTab('personal');
        }
        if (prevProps.continueWithEmail.success === false && this.props.continueWithEmail.success === true) {
            this.setState({checkmailexists: false})
            this.props.completePersonalDetails(this.props.continueWithEmail.data);
        }
        //console.log(this.props.continueWithEmail)
        if (prevProps.continueWithEmail.data !== this.props.continueWithEmail.data &&
            this.props.continueWithEmail.success === false &&
            this.props.continueWithEmail.message !== '' &&
            this.props.continueWithEmail.message !== 'succeess' &&
            this.state.checkmailexists === true) {
            this.setState({checkmailexists: false})
            this.props.toggleModal(
                true,
                'Email Verification',
                this.props.continueWithEmail.message,
                this.props.toggleModal,
                false,
                this.props.toggleModal,
                'md'
            );
        }
        let razOrder = window.order ? JSON.parse(window.order) : {};
        let payment = razOrder.payment ? razOrder.payment : '';
        let orderId = razOrder.orderId ? razOrder.orderId : '';
        let paymentId = razOrder.orderId ? razOrder.paymentId : '';
        let signature = razOrder.signature ? razOrder.signature : '';
        //console.log(razOrder);
        if (this.props.orderPlaced.orderId !== prevProps.orderPlaced.orderId && this.props.orderPlaced.orderId !== '' && this.props.orderPlaced.orderId !== undefined) {
            //console.log("2--------------");
            this.setState({orderId: this.props.orderPlaced.orderId, paymentStatus: ''});
            let customerPlan = localStorage.getItem('plan') ? JSON.parse(localStorage.getItem('plan')) : {};
            let selectedPlan = customerPlan.selectedPlan;
            if ((this.props.selectedPlan && this.props.selectedPlan.id)) {
                selectedPlan = this.props.selectedPlan;
            }
            let refer = new URL(window.location.href).searchParams.get("refer");
            let udetails = localStorage.getItem('ud') ? JSON.parse(atob(localStorage.getItem('ud'))) : {};
            //console.log(customerPlan);
            //console.log(selectedPlan);
            localStorage.setItem('plan', JSON.stringify(
                {
                    orderId: this.props.orderPlaced.orderId,
                    terms: this.state.terms,
                    loginType: udetails['login type'] ? udetails['login type'] : this.state.loginType,
                    name: this.state.name,
                    email: this.state.email,
                    mobile: this.state.mobile,
                    profession: this.state.profession,
                    dob: this.state.dob,
                    password: this.state.password,
                    selectedPlan: selectedPlan,
                    refer: refer,
                    paymentId: paymentId,
                    user_category: udetails['user_category']
                }))
            document.getElementById('razorpay-order-id').value = this.props.orderPlaced.orderId;
            document.getElementById('razorpay-order-form').submit();
        }


        let _oId = localStorage.getItem('_oId') ? window.atob(localStorage.getItem('_oId')) : '';
        let payError = '';
        if(payment === 'success') {
            payment = _oId === orderId ? 'success' : 'failed';
            payError = _oId === orderId ? payError : 'Payment Failed';
        }
        let sha256 = require('js-sha256');
        let signatureCalculated = sha256.hmac(RAZORPAY_KEY_SECRET, orderId + '|' + paymentId);
        //console.log(sha256.hmac(RAZORPAY_KEY_SECRET, orderId + '|' + paymentId));
        if(payment === 'success') {
            payment = signatureCalculated === signature ? 'success' : 'failed';
            payError = signatureCalculated === signature ? payError : 'Payment Failed';
        }
        if (payment === 'success' && this.state.paymentStatus !== 'success') {
            //console.log("3--------------");
            let customerPlan = JSON.parse(localStorage.getItem('plan'));

            this.setState({
                paymentStatus: payment,
                terms: customerPlan.terms,
                loginType: customerPlan.loginType,
                name: customerPlan.name,
                email: customerPlan.email,
                mobile: customerPlan.mobile,
                profession: customerPlan.profession,
                dob: customerPlan.dob,
                password: customerPlan.password,
                selectedPlan: customerPlan.selectedPlan,
            });
            //console.log('orderId: ' + orderId);
            //console.log('this.state.orderId: ' + this.state.orderId);
            //console.log('paymentId: ' + paymentId);
            //console.log('this.state.paymentId: ' + this.state.paymentId);
            if (orderId !== '' && orderId !== this.state.orderId && paymentId !== this.state.paymentId) {
                //console.log('orderId: ' + orderId);
                //console.log('paymentId: ' + paymentId);
                customerPlan.status = "Success";
                customerPlan.orderId = orderId;
                customerPlan.paymentId = paymentId;
                this.setState({orderId: orderId, paymentId: paymentId, status: "Success", customerPlan: customerPlan});

                this.props.existsTransaction(customerPlan);


            }
        } else if (payment === 'failed' && this.state.paymentStatus !== 'failed') {
            //console.log("4--------------");
            let customerPlan = JSON.parse(localStorage.getItem('plan'));

            this.setState({
                paymentStatus: payment,
                terms: customerPlan.terms,
                loginType: customerPlan.loginType,
                name: customerPlan.name,
                email: customerPlan.email,
                mobile: customerPlan.mobile,
                profession: customerPlan.profession,
                dob: customerPlan.dob,
                password: customerPlan.password,
                selectedPlan: customerPlan.selectedPlan,
                paymentId: paymentId,
                orderId: orderId,
                payError: payError,
                status: 'Failure'
            });
            /*let description = new URL(window.location.href).searchParams.get("description") || '';

            let step = new URL(window.location.href).searchParams.get("step") || '';
            if (description !== '') {
                console.log('description: ' + description);
                console.log('step: ' + step);
            }*/
            //this.setState({paymentFailure: {description: description, step: step}});
            /*this.props.toggleModal(
                true,
                'Message',
                'Sorry.. '  + description + '.<br>Please try again. Thank You.',
                this.gotToSubscribe,
                false,
                this.props.toggleModal,
                'md'
            );*/
            customerPlan.status = "Failure";
            customerPlan.paymentId = paymentId;
            customerPlan.orderId = orderId;
            customerPlan.payError = payError;
            this.setState({customerPlan: customerPlan});
            //console.log(customerPlan)
            if(payError === '') {
                this.props.existsTransaction(customerPlan);
            } else {
                let razOrder = window.order ? JSON.parse(window.order) : {};
                razOrder.payment = 'failed';
                window.order = JSON.stringify(razOrder);
                this.setState({
                    paymentInfo:{status: 'failed', 'order_id': orderId, amount: parseInt(customerPlan.selectedPlan['text_offer_price'].replace('Rs. ', '')) * 100, error_description: payError}
                });
            }

        }
        console.log(this.state.paymentInfo)
        console.log(this.props.orderPaymentInfo)
        console.log(this.props.transactionInfo)
        console.log(prevProps.transactionInfo.status)
        if (this.props.transactionInfo.status !== prevProps.transactionInfo.status  && this.props.transactionInfo.status === false ) {
            //this.props.ohariPaymentInfo(this.state.customerPlan);
            console.log('this.state.status:' + this.state.status);
            if(this.state.status === 'Success') {
                //console.log("5--------------");
                /*let customerPlan = this.state.customerPlan;
                let plan = JSON.parse(localStorage.getItem('plan'));
                if(plan.refer === 'renew') {
                    customerPlan.refer = plan.refer;
                }
                this.paymentDetails(customerPlan, this.state.customerPlan.paymentId)
                this.props.saveSignupPayment(this.state.customerPlan);*/
                this.props.ohariPaymentInfo(this.state.customerPlan);
            } else {
                //console.log("6--------------");
                this.props.ohariPaymentInfo(this.state.customerPlan);

            }
        }
        if (this.props.transactionInfo.status !== prevProps.transactionInfo.status  && this.props.transactionInfo.status === true ) {
            //console.log("7--------------");
            if(payment === 'failed') {
                this.setState({
                    paymentInfo: {
                        status: 'failed',
                        'order_id': this.state.customerPlan.orderId,
                        amount: parseInt(this.state.customerPlan.selectedPlan['text_offer_price'].replace('Rs. ', '')) * 100,
                        error_description: razOrder.error
                    }
                });
            }
            if(payment === 'success') {
                this.setState({
                    paymentInfo: {
                        status: 'failed',
                        'order_id': this.state.customerPlan.orderId,
                        amount: parseInt(this.state.customerPlan.selectedPlan['text_offer_price'].replace('Rs. ', '')) * 100,
                        error_description: 'Payment Failed'
                    }
                });
            }
        }
        if (this.props.orderPaymentInfo.data !== this.state.paymentInfo && this.props.orderPaymentInfo.data.status === "failed") {
            //console.log("8--------------");
            let plan = JSON.parse(localStorage.getItem('plan'));
            this.props.saveSignupPayment(this.state.customerPlan, plan.selectedPlan, (plan.refer === 'renew' || plan.refer === 'upgrade') ? 'Renewal' : 'New');
            this.setState({paymentInfo: this.props.orderPaymentInfo.data});
        }
        //console.log(localStorage.getItem('flag'))
        //console.log(this.props.orderPaymentInfo)
        if (this.props.orderPaymentInfo.data.status === "captured" && localStorage.getItem('flag') !== 'up') {
            //console.log("9--------------");
            let amt = this.props.orderPaymentInfo.data.amount;
            let cncy = this.props.orderPaymentInfo.data.currency;
            //let stat = this.props.orderPaymentInfo.data.status;
            let _amt = localStorage.getItem('_oAmt') ? window.atob(localStorage.getItem('_oAmt')) : '';
            let _cncy = localStorage.getItem('_oCry') ? window.atob(localStorage.getItem('_oCry')) : '';
            //let _rcpt= localStorage.getItem('_oRct') ? window.atob(localStorage.getItem('_oRct')) : '';
            //console.log(amt)
            //console.log(_amt)
            //console.log(cncy)
            //console.log(_cncy)
            if(parseInt(amt) === parseInt(_amt) && cncy === _cncy) {
                //console.log("10--------------");
                let customerPlan = this.state.customerPlan;
                let plan = JSON.parse(localStorage.getItem('plan'));
                if (plan.refer === 'renew' || plan.refer === 'upgrade') {
                    customerPlan.refer = plan.refer;
                }
                this.paymentDetails(customerPlan, this.state.customerPlan.paymentId)
                this.props.saveSignupPayment(this.state.customerPlan, plan.selectedPlan, (plan.refer === 'renew' || plan.refer === 'upgrade') ? 'Renewal' : 'New');
            } else {
                //console.log("11--------------");
                let razOrder = window.order ? JSON.parse(window.order) : {};
                razOrder.payment = 'failed';
                window.order = JSON.stringify(razOrder);
                this.setState({
                    paymentInfo: {
                        status: 'failed',
                        'order_id': this.state.customerPlan.orderId,
                        amount: parseInt(this.state.customerPlan.selectedPlan['text_offer_price'].replace('Rs. ', '')) * 100,
                        error_description: 'Payment Failed'
                    }
                });
                localStorage.setItem('flag', 'up');
            }
        }
        if(this.props.OTPSend.status !== prevProps.OTPSend.status && this.props.OTPSend.status === true) {
            this.setState({
                otp: '',
                otpSent: true,
                otpConfirmed: false
            });
            let counter = 180;
            this.setState({otpResendTimer: counter});
            //let resend = false;
            let obj = this;
            clearInterval(obj.t);
            this.t = setInterval(function () {
                //console.log(counter);
                counter--
                obj.setState({otpResendTimer: counter});
                if (counter === 0) {
                    obj.setState({otpResendButton: true});
                    clearInterval(obj.t)
                }
            }, 1000);
        }
    }
    getGeoInfo = () => {
        axios.get('https://ipapi.co/json/').then((response) => {
            let data = response.data;
            this.setState({
                countryName: data.country_name,
                countryCode: data.country_calling_code
            });
        }).catch((error) => {
            // console.log(error);
        });
    };
    componentDidMount() {
        this.getGeoInfo();
        let customerPlan = localStorage.getItem('plan') ? JSON.parse(localStorage.getItem('plan')) : {};
        let _oId = localStorage.getItem('_oId') ? window.atob(localStorage.getItem('_oId')) : '';
        let razOrder = window.order ? JSON.parse(window.order) : {}
        this.props.transactionsAPILog({name: customerPlan.name || '', orderId: _oId || '', action: 'Plan ComponentMount', info: razOrder['razorpay_callback'] ? window.atob(razOrder['razorpay_callback']) : ''});

        let cb = localStorage.getItem('cb') ? localStorage.getItem('cb') : '';

        if(razOrder['razorpay_callback'] && cb === '') {
            localStorage.setItem('cb', '1');
            //let customerPlan = localStorage.getItem('plan') ? JSON.parse(localStorage.getItem('plan')) : {};
            //let _oId = localStorage.getItem('_oId') ? window.atob(localStorage.getItem('_oId')) : '';
            this.props.transactionsAPILog({name: customerPlan.name || '', orderId: _oId || '', action: 'Callback', info: window.atob(razOrder['razorpay_callback'])});

        }

        //console.log(this.props.signupData.email)
        //let customerPlan = localStorage.getItem('plan') ? JSON.parse(localStorage.getItem('plan')) : {};
        let ud = localStorage.getItem('ud') ? JSON.parse(atob(localStorage.getItem('ud'))) : {};
        if (this.state.email !== this.props.signupData.email && this.props.signupData.email && this.props.signupData.email !== '') {
            this.setState({
                name: this.props.signupData.name || '',
                email: this.props.signupData.email || '',
                mobile: this.props.signupData.mobile || '',
                password: this.props.signupData.password || '',
                confirmpassword: this.props.signupData.password || '',
                loginType: this.props.signupData.logintype || 'internal',
                profession: this.props.signupData.profession || '',
                dob: this.props.signupData.dob || '',
                user_category: customerPlan.user_category || 'Non Bsynapse User'
            });
        } else if(customerPlan.email && this.state.email !== customerPlan.email)  {
            this.setState({
                name: customerPlan.name || '',
                email: customerPlan.email || '',
                mobile: customerPlan.mobile || '',
                password: customerPlan.password || '',
                confirmpassword: customerPlan.password || '',
                loginType: customerPlan.logintype || 'internal',
                profession: customerPlan.profession || '',
                dob: customerPlan.dob || '',
                user_category: customerPlan.user_category || 'Non Bsynapse User'
            });
        } else if(ud.email  && ud.user_category === 'Bsynapse User') {

            this.setState({
                name: ud.name || '',
                email: ud.email || '',
                mobile: ud.mobile || '',
                password: ud.password || '',
                confirmPassword: ud.password || '',
                loginType: ud.logintype || 'internal',
                profession: ud.profession || '',
                dob: ud.dob || '',
                user_category: 'Bsynapse User'
            });
        }
    }

    render() {
        //console.log('render');
        //console.log('this.props');
        //console.log(this.props);
        let customerPlan = localStorage.getItem('plan') ? JSON.parse(localStorage.getItem('plan')) : {};
        //console.log('customerPlan');
        //console.log(customerPlan);

        let selectedPlan = customerPlan.selectedPlan;
        if ((this.props.selectedPlan && this.props.selectedPlan.id)) {
            selectedPlan = this.props.selectedPlan;
        }
        if ((this.props.selectedPlan && this.props.selectedPlan.id) || (customerPlan.selectedPlan && customerPlan.selectedPlan.id)) {
            //console.log('render --- if 2');
        } else {
            //console.log('render --- else 2');
            return <Redirect to="/subscribe"/>
        }

        /*let attr = {
            paymenttextcolor: '#FFFFFF',
            paymenttext: 'Pay Now',
            paymentbgcolor: '#903e97',
            paymentbuttontype: 'button'
        };*/

        //console.log('otpSent: ' + this.state.otpSent);
        //console.log('otpResendTimer: ' + this.state.otpResendTimer);
        //console.log('OTPSend: ' + this.props.OTPSend.success);
        //console.log('verifiedOTP: ' + this.props.OTPverified.success);
        //console.log(this.state.otpSent === true && this.state.otpResendTimer > 0);
        let refer = new URL(window.location.href).searchParams.get("refer");
        let razOrder = window.order ? JSON.parse(window.order) : {};
        let payment = razOrder.payment ? razOrder.payment : '';
        let planTab = refer === 'upgrade' ? 'payment' : this.props.planTab;
        //console.log(razOrder)
        //console.log(customerPlan)
        //console.log(this.state)
        return (
            <React.Fragment>
                {(payment === 'success') &&
                <div className="story-placeholder">
                    <p>Please wait...</p>
                </div>
                }
                {payment === 'failed' &&
                <React.Fragment>
                    {!this.state.paymentInfo.status &&
                    <div className="story-placeholder">
                        <p>Please wait...</p>
                    </div>
                    }
                    {this.state.paymentInfo.status &&
                    <React.Fragment>
                        <div className="anchor-links">
                            <Link onClick={(e) => this.props.changeSection('')}>Home</Link> / <Link
                            className="current-page">Subscription</Link>
                        </div>
                        <div className="boiler-panel">
                            <div className="boiler-banner">
                                <h5>Subscription Payment Status</h5>
                            </div>
                            <div className="boiler-content">


                                <div className="account-inner-content">
                                    <p className="failed-status">
                                        Sorry.. your payment has failed. Please try again. Thank You.
                                    </p>
                                    <p><span
                                        className="font-bold">Order Number:</span> {this.state.paymentInfo.order_id}</p>
                                    <p><span
                                        className="font-bold">Amount:</span> Rs.{this.state.paymentInfo.amount / 100}/-
                                    </p>
                                    <p><span
                                        className="font-bold">Message:</span> {this.state.paymentInfo.error_description}
                                    </p>
                                    <div className="form-submit-block">
                                        <button className="border-btn" onClick={this.gotToSubscribe}>Back to
                                            Subscription
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    }
                </React.Fragment>
                }
                {(payment !== 'success' && payment !== 'failed') &&
                <React.Fragment>
                    <div className="anchor-links">
                        <Link onClick={(e) => this.props.changeSection('')}>Home</Link> / <Link className="current-page">Subscription
                        Plans</Link>
                    </div>

                    <div className="boiler-panel">


                        <div className="boiler-banner">
                            <p>Subscription Plans</p>
                            <h5>{selectedPlan['text_plan']}</h5>

                        </div>


                        <div className="boiler-nav-tab">
                            <div className="boiler-nav">
                                <Link className={`${planTab === 'personal' ? 'active' : ''}`}>Personal
                                    Details</Link>
                                <Link className={`${planTab === 'terms' ? 'active' : ''}`}>Terms of Use</Link>
                                <Link className={`${planTab === 'payment' ? 'active' : ''}`}>Payment</Link>
                            </div>
                            <div className="boiler-content">
                                {planTab === 'personal' &&
                                <div className="form-container">

                                    <div class="each-input-field">
                                        <label>Full Name</label>
                                        <input type="text" name="name" value={this.state.name}
                                               onChange={this.handleChange} disabled={this.state.user_category === 'Bsynapse User' ? 'disabled' : ''}/>
                                        <div
                                            className={`error-field${this.state.error.name === '' ? ' hide' : ''}`}>{this.state.error.name}</div>
                                    </div>
                                    <div class="each-input-field">
                                        <label>Email</label>
                                        <input type="text" name="email" value={this.state.email}
                                               onChange={this.handleChange}  disabled={this.state.user_category === 'Bsynapse User' ? 'disabled' : ''}/>
                                        <div
                                            className={`error-field${this.state.error.email === '' ? ' hide' : ''}`}>{this.state.error.email}</div>
                                    </div>
                                    <div class="each-input-field with-code">
                                        <label>Mobile Number</label>
                                        <span className="country-code">{this.state.countryCode}</span>
                                        <input type="number" name="mobile" value={this.state.mobile.replace(this.state.countryCode, '')}
                                               onChange={this.handleChange} autocomplete="off"/>
                                        <div
                                            className={`error-field${this.state.error.mobile === '' ? ' hide' : ''}`}>{this.state.error.mobile}</div>
                                    </div>
                                    <div class="each-input-field">
                                        <label>Profession</label>
                                        <div className="select-box">
                                            <select name="profession" value={this.state.profession}
                                                    onChange={this.handleChange}>
                                                <option>Select Profession</option>
                                                <option>Business</option>
                                                <option>Self Employed</option>
                                                <option>Employed - Private</option>
                                                <option>Employed - Government</option>
                                                <option>Agriculture</option>
                                                <option>Student</option>
                                                <option>Homemaker</option>
                                                <option>Other</option>
                                            </select>
                                        </div>
                                        <div
                                            className={`error-field${this.state.error.profession === '' ? ' hide' : ''}`}>{this.state.error.profession}</div>
                                    </div>
                                    <div class="each-input-field">
                                        <label>Date of Birth</label>

                                        <DatePicker
                                            calendarAriaLabel="Toggle calendar"
                                            clearAriaLabel="Clear value"
                                            dayAriaLabel="Day"
                                            monthAriaLabel="Month"
                                            nativeInputAriaLabel="Date"
                                            onChange={(date) => this.handleDateChange(date, 'dob')}
                                            value={this.state.dob}
                                            yearAriaLabel="Year"
                                            dayPlaceholder="dd"
                                            monthPlaceholder="mm"
                                            yearPlaceholder="yyyy"
                                            format="dd/MM/yyyy"
                                            maxDate={new Date()}
                                        />
                                        <div
                                            className={`error-field${this.state.error.dob === '' ? ' hide' : ''}`}>{this.state.error.dob}</div>
                                    </div>
                                    {this.state.user_category !== 'Bsynapse User' &&
                                    <React.Fragment>
                                        <div className="each-input-field">
                                            <label>Password</label>
                                            <input type="password" name="password" value={this.state.password}
                                                   onChange={this.handleChange}/>
                                            <div
                                                className={`error-field${this.state.error.password === '' ? ' hide' : ''}`}>{this.state.error.password}</div>
                                        </div>
                                        <div className="each-input-field">
                                            <label>Confirm Password</label>
                                            <input type="password" name="confirmPassword"
                                                   value={this.state.confirmPassword}
                                                   onChange={this.handleChange}/>
                                            <div
                                                className={`error-field${this.state.error.confirmPassword === '' ? ' hide' : ''}`}>{this.state.error.confirmPassword}</div>
                                        </div>
                                    </React.Fragment>
                                    }
                                    <div className="form-submit-block">
                                        <button class="border-btn" onClick={(e) => this.showNext(1)}>NEXT</button>
                                    </div>
                                    {/*{this.state.user_category !== 'Bsynapse User' &&
                                    <div className="alt-login">
                                        <div className="divider-text">
                                            <span>Or</span>
                                        </div>
                                        <div className="alt-login-box">
                                            <GoogleLogin
                                                clientId={GOOGLE_KEY}
                                                buttonText="Signup with Google"
                                                className="gl long icon-btn btn"
                                                onSuccess={this.signupGoogle}
                                                onFailure={this.props.signupGoogle}
                                                icon={false}
                                                cookiePolicy={"single_host_origin"}
                                                prompt={"consent"}
                                            />
                                            <FacebookLogin
                                                appId={FB_KEY}
                                                fields="first_name,last_name,email,picture"
                                                callback={this.signupFB}
                                                textButton="Signup with Facebook"
                                                cssClass="fb long icon-btn btn"
                                                isMobile={false}
                                                autoLoad={false}
                                                responseType="codesignup"
                                            />
                                        </div>
                                    </div>
                                    }*/}
                                </div>

                                }

                                {planTab === 'terms' &&
                                <div className="terms-condition">

                                    <div
                                        dangerouslySetInnerHTML={{__html: this.props.pageContents['Terms of Use'] ? this.props.pageContents['Terms of Use'].replace(/<p><br><\/p>/gm, '').replace(/<p>&nbsp;<\/p>/gm, '') : ''}}>
                                    </div>
                                    <div className="each-input-field">

                                        <div className="checkbox-wrapper">
                                            <div class="checkbox">
                                                <label>I agree the terms and conditions.
                                                    <input type="checkbox" name="terms" checked={this.state.terms}
                                                           onChange={this.handleChange}/>
                                                    <div class="control-indicator"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div
                                            className={`error-field${this.state.error.terms === '' ? ' hide' : ''}`}>{this.state.error.terms}</div>
                                    </div>
                                    <div class="form-submit-block">
                                        {/*<button class="border-btn" onClick={(e) => this.showPrevious('personal')}>PREVIOUS</button>*/}
                                        <button class="border-btn" onClick={(e) => this.showNext(2)}>NEXT</button>
                                    </div>
                                </div>
                                }
                                {planTab === 'payment' &&
                                <div className="payment-container">

                                    {(this.state.proceedToPay === false) &&
                                    <React.Fragment>
                                        <div className="form-container">

                                            <React.Fragment>
                                                <div class="terms-condition">
                                                    <p className="font-bold">Confirm mobile number and click 'PROCEED' to make payment. </p>
                                                </div>

                                                <div className="each-input-field with-code">
                                                    <label>Mobile Number</label>

                                                    {this.state.countryCode !== '' &&
                                                    <React.Fragment>
                                                        <span className="country-code">{this.state.countryCode}</span>

                                                        <input type="number" name="mobile" value={this.state.mobile.replace(this.state.countryCode, '')}
                                                               onChange={this.handleChange} />
                                                    </React.Fragment>
                                                    }


                                                    <div
                                                        className={`error-field${this.state.error.mobile === '' ? ' hide' : ''}`}>{this.state.error.mobile}</div>


                                                </div>
                                                <div class="form-submit-block">
                                                    <button className="border-btn" onClick={(e) => this.proceedToPay()}>PROCEED</button>
                                                </div>
                                            </React.Fragment>



                                        </div>
                                    </React.Fragment>
                                    }
                                    {(this.state.proceedToPay === true) &&
                                    <React.Fragment>
                                        <div className="small-header">
                                            <h5>You have selected</h5>
                                        </div>
                                        <div className="account-inner-content">
                                            <p>{selectedPlan['text_plan']}</p>
                                            <p>{selectedPlan['text_offer_price']}/-</p>
                                        </div>


                                        <div className="form-submit-block">

                                            <button className="border-btn" onClick={(e) => this.props.placeOrder({
                                                amount: parseInt(selectedPlan['text_offer_price'].replace('Rs. ', '')) * 100,
                                                currency: "INR",
                                                receipt: "hedgeohari_subscription",
                                                payment_capture: 1
                                            }, this.state, selectedPlan, (refer === 'renew' || refer === 'upgrade') ? 'Renewal' : 'New')}>Pay Now
                                            </button>

                                        </div>
                                        {this.props.orderPlaced.orderId !== '' &&
                                        <div className="form-submit-block">

                                            <form id="razorpay-order-form" method="POST"
                                                  action="https://api.razorpay.com/v1/checkout/embedded">
                                                <input type="hidden" name="key_id" value={RAZORPAY_KEY_ID}/>
                                                <input type="hidden" name="order_id" id="razorpay-order-id"
                                                       value={this.props.orderPlaced.orderId}
                                                />
                                                <input type="hidden" name="amount"
                                                       value={`${parseInt(selectedPlan['text_offer_price'].replace('Rs. ', '')) * 100} in paisa`}/>
                                                <input type="hidden" name="name" value={this.state.name}/>
                                                <input type="hidden" name="description"
                                                       value="HedgeOhari Subscription"/>
                                                <input type="hidden" name="prefill[email]" value={this.state.email}/>
                                                <input type="hidden" name="prefill[contact]" value={this.state.mobile}/>
                                                <input type="hidden" name="notes[transaction_id]"
                                                       value="hedgeohari_subscription"/>
                                                <input type="hidden" name="callback_url"
                                                       value={`${HOME_PAGE}plan`}/>
                                                <button type="submit" className="border-btn hide">Pay Now</button>
                                            </form>


                                        </div>
                                        }
                                    </React.Fragment>
                                    }
                                    {/*<div class="form-submit-block">
                                <button class="border-btn" onClick={(e) => this.showPrevious('terms')}>PREVIOUS</button>
                            </div>*/}
                                </div>
                                }
                                {/*
                                {planTab === 'payment' &&
                                <div className="payment-container">

                                    {((this.state.otpConfirmed === false ||
                                        (this.state.otpConfirmed === true && this.props.OTPverified.success === false))) &&
                                    <React.Fragment>
                                        <div className="form-container">
                                            {(this.state.otpSent === false ||
                                                (this.state.otpSent === true && this.props.OTPSend.success === false) ||
                                                this.state.changeNumber === true) &&
                                            <React.Fragment>
                                                <div class="terms-condition">
                                                    <p className="font-bold">Click 'Get OTP' for verifying your mobile
                                                        number for proceeding to make payment. </p>
                                                </div>

                                                <div className="each-input-field with-code">
                                                    <label>Mobile Number</label>

                                                    {this.state.countryCode !== '' &&
                                                        <React.Fragment>
                                                            <span className="country-code">{this.state.countryCode}</span>

                                                            <input type="text" name="mobile" value={this.state.mobile.replace(this.state.countryCode, '')}
                                                            onChange={this.handleChange}/>
                                                        </React.Fragment>
                                                    }


                                                    {this.props.OTPSend.success === false &&
                                                    <div
                                                        className={`error-field${this.props.OTPSend.message === '' ? ' hide' : ''}`}>{this.props.OTPSend.message}</div>
                                                    }
                                                    <div
                                                        className={`error-field${this.state.error.mobile === '' ? ' hide' : ''}`}>{this.state.error.mobile}</div>


                                                </div>
                                                <div class="form-submit-block">
                                                    <button className="border-btn" onClick={(e) => this.getOTP()}>GET
                                                        OTP
                                                    </button>
                                                </div>
                                            </React.Fragment>
                                            }
                                            {(this.state.otpSent === true && this.props.OTPSend.success === true && this.state.changeNumber === false) &&
                                            <React.Fragment>
                                                <div className="each-input-field">
                                                    {this.state.otpConfirmed === false &&
                                                    <div class="terms-condition">
                                                        <p className="font-bold">OTP sent successfully to your mobile.
                                                            Pls
                                                            enter OTP and click 'verify' to proceed.</p>
                                                    </div>
                                                    }

                                                    <label>Enter OTP</label>
                                                    <input type="text" name="otp" value={this.state.otp}
                                                           onChange={this.handleChange}/>
                                                    <div
                                                        className={`error-field${this.state.error.otp === '' ? ' hide' : ''}`}>{this.state.error.otp}</div>
                                                    {(this.state.otpConfirmed === true && this.props.OTPverified.success === false) &&
                                                    <div
                                                        className={`error-field`}>{this.props.OTPverified.message}</div>
                                                    }
                                                </div>
                                                <div class="form-submit-block">

                                                    <div className="resend-block">
                                                        <div>
                                                            {(this.state.otpSent === true && this.state.otpResendTimer > 0) &&
                                                            <p>The OTP is valid for <span
                                                                className="timer">{this.state.otpResendTimer} seconds</span>
                                                            </p>
                                                            }
                                                        </div>

                                                        <div className="button-right">
                                                            <button className="border-btn"
                                                                    onClick={(e) => this.verifyOTP()}>VERIFY
                                                            </button>
                                                            {(this.state.otpSent === true && this.state.otpResendButton === true && this.state.changeNumber === false && this.state.otpResendTimer === 0) &&
                                                            <React.Fragment>
                                                                <button className="border-btn"
                                                                        onClick={(e) => this.resendOTP()}>Resend OTP
                                                                </button>
                                                                <button className="border-btn" onClick={this.resetNumber}>Change
                                                                    Number</button>

                                                            </React.Fragment>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>

                                            }

                                        </div>
                                    </React.Fragment>
                                    }
                                    {((this.state.otpConfirmed === true && this.props.OTPverified.success === true)) &&
                                    <React.Fragment>
                                        <div className="small-header">
                                            <h5>You have selected</h5>
                                        </div>
                                        <div className="account-inner-content">
                                            <p>{selectedPlan['text_plan']}</p>
                                            <p>{selectedPlan['text_offer_price']}/-</p>
                                        </div>


                                        <div className="form-submit-block">

                                            <button className="border-btn" onClick={(e) => this.props.placeOrder({
                                                amount: parseInt(selectedPlan['text_offer_price'].replace('Rs. ', '')) * 100,
                                                currency: "INR",
                                                receipt: "hedgeohari_subscription",
                                                payment_capture: 1
                                            })}>Pay Now
                                            </button>

                                        </div>
                                        {this.props.orderPlaced.orderId !== '' &&
                                        <div className="form-submit-block">

                                            <form id="razorpay-order-form" method="POST"
                                                  action="https://api.razorpay.com/v1/checkout/embedded">
                                                <input type="hidden" name="key_id" value={RAZORPAY_KEY_ID}/>
                                                <input type="hidden" name="order_id" id="razorpay-order-id"
                                                       value={this.props.orderPlaced.orderId}
                                                />
                                                <input type="hidden" name="amount"
                                                       value={`${parseInt(selectedPlan['text_offer_price'].replace('Rs. ', '')) * 100} in paisa`}/>
                                                <input type="hidden" name="name" value={this.state.name}/>
                                                <input type="hidden" name="description"
                                                       value="HedgeOhari Subscription"/>
                                                <input type="hidden" name="prefill[email]" value={this.state.email}/>
                                                <input type="hidden" name="prefill[contact]" value={this.state.mobile}/>
                                                <input type="hidden" name="notes[transaction_id]"
                                                       value="hedgeohari_subscription"/>
                                                <input type="hidden" name="callback_url"
                                                       value={`${HOME_PAGE}plan`}/>
                                                <button type="submit" className="border-btn hide">Pay Now</button>
                                            </form>


                                        </div>
                                        }
                                    </React.Fragment>
                                    }

                                </div>
                                }
*/}
                            </div>
                        </div>


                    </div>
                </React.Fragment>
                }
            </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        orderPlaced: actionReducers.getPlaceOrder(state),
        orderPaymentInfo: actionReducers.getPaymentInfo(state),
        transactionInfo: actionReducers.getTransactionInfo(state)
    }
}
export default connect(mapStateToProps, {
    placeOrder: ActionCreators.placeOrder,
    saveSignupPayment: ActionCreators.saveSignupPayment,
    renewSubscriber: ActionCreators.renewSubscriber,
    ohariPaymentInfo: ActionCreators.ohariPaymentInfo,
    existsTransaction: ActionCreators.existsTransaction,
    transactionsAPILog: ActionCreators.transactionsAPILog
})(Plan);
