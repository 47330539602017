import React, { Component } from "react";


import moment from "moment";
import * as actionReducers from "../../reducers";
import {connect} from "react-redux";
import * as ActionCreators from "../../actions";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import Share from '../views/Share';
import InfiniteScrollPanel from "../base_levels/InfiniteScrollPanel";

import {IMG_PATH, VIDEO_PATH} from "../../constants";

class Home extends Component {
    constructor(props) {
        super();
        this.state = {
            section: '',
            search: '',
            searchFilterParams:  JSON.stringify({}),
            searchByFilter: false,
            storyToShare: '',
            collectionStories: {data:[],count:0,totalitems:0,page:1},
            bookmarks: [],
            bookmarksIds: []
        }
        this.showShare = this.showShare.bind(this);
        this.addBookmark = this.addBookmark.bind(this);
        this.removeBookmark = this.removeBookmark.bind(this);
        this.bookmarkAction = this.bookmarkAction.bind(this);
        /*if (window.performance) {
            console.log(window.performance)
            if (window.performance.navigation.type == 1) {
                let ud = localStorage.getItem("ud") ? JSON.parse(atob(localStorage.getItem("ud"))) : {};
                localStorage.clear();
                sessionStorage.clear();
                localStorage.setItem('allstories', true);
                localStorage.setItem('ud', btoa(JSON.stringify(ud)));
            }
        }*/
    }

    showShare(id) {
        this.setState({storyToShare: id});
    }
    goToDetails(id, paidNews, newsLink) {
        localStorage.setItem('box', id);
        localStorage.setItem('page', this.state.collectionStories.page);
        localStorage.setItem('refer', '/');
        sessionStorage.setItem('collectionStories', JSON.stringify(this.state.collectionStories))
        //history.push(newsLink)
        window.location = newsLink;
    }
    bookmarkAction(story) {
        let indx = this.state.bookmarksIds.indexOf(story['uniqueid'])
        if(indx < 0) {
            this.addBookmark(story);
        } else {
            this.removeBookmark(story)
        }
    }
    addBookmark(story) {
        //console.log(story);
        let data = story;
        data.userId = this.props.user.userId
        this.props.addBookmarks(data);
    }
    removeBookmark(story) {
        //console.log(story);
        //console.log(this.state.bookmarks);
        let recordId = this.state.bookmarks.filter((a) => (a['story-id'] === story['uniqueid']))[0]['id'];
        let data = story;
        data.userId = this.props.user.userId;
        data.recordId = recordId;

        this.props.deleteBookmarks(data);
    }
    fetchMoreData=()=>{
        //console.log('loadMore')
        this.getColl('loadMore')
    }
    getColl=(from)=>{
        let search = localStorage.getItem('search') ? localStorage.getItem('search') : '';
        let section = localStorage.getItem('section') ? localStorage.getItem('section') : '';
        //let searchByFilter = localStorage.getItem('searchByFilter') ? JSON.parse(localStorage.getItem('searchByFilter')) : false;
        let searchFilterParams = localStorage.getItem('searchFilterParams') ? JSON.parse(localStorage.getItem('searchFilterParams')) : {};

        let filterSections = searchFilterParams.filterSections ? searchFilterParams.filterSections : section;
        let filterCategories = searchFilterParams.filterCategories ? searchFilterParams.filterCategories : "";

        let params = {section: ''};
        if(filterSections !== '') {
            params.section = filterSections;
        }
        if(search !== '') {
            params.search = search;
        }
        if(filterCategories !== '') {
            params.category = filterCategories;
        }
        params.page = this.state.collectionStories.page + 1;
        if(searchFilterParams.startDate) {
            params.pdate = searchFilterParams.startDate + ',' + searchFilterParams.endDate;
        }
        params.dataFrom = from;
        //.log('############# getCol ############');
        //console.log('getColl')

        this.props.findStories(params, this.props.sections);

    }




    componentWillMount() {
        //console.log('componentDidMount')
        //const container = document.querySelector('.section-main');
        //container.addEventListener('scroll', this.listenToScroll)
        let search = localStorage.getItem('search') ? localStorage.getItem('search') : '';
        let section = localStorage.getItem('section') ? localStorage.getItem('section') : '';
        //let searchByFilter = localStorage.getItem('searchByFilter') ? JSON.parse(localStorage.getItem('searchByFilter')) : false;
        let searchFilterParams = localStorage.getItem('searchFilterParams') ? JSON.parse(localStorage.getItem('searchFilterParams')) : {};

        let filterSections = searchFilterParams.filterSections ? searchFilterParams.filterSections : section;
        let filterCategories = searchFilterParams.filterCategories ? searchFilterParams.filterCategories : "";

        let params = {section: ''};
        if(filterSections !== '') {
            params.section = filterSections;
        }
        if(search !== '') {
            params.search = search;
        }
        if(filterCategories !== '') {
            params.category = filterCategories;
        }
        if(searchFilterParams.startDate) {
            params.pdate = searchFilterParams.startDate + ',' + searchFilterParams.endDate;
        }
        //let page = localStorage.getItem('page') ? localStorage.getItem('page') : ''
        //console.log('######### componentWillMount' + window.mode === '' );
        params.dataFrom = 'main';
        let allstories = localStorage.getItem('allstories') ? localStorage.getItem('allstories') : 'true';
        let Data = (!sessionStorage.getItem('collectionStories') || sessionStorage.getItem('collectionStories') === '') ? '' : JSON.parse(sessionStorage.getItem('collectionStories'));

        if(allstories !== 'false' || (window.mode === '' || window.mode === undefined) || Data === '') {
            //console.log('componentWillMount')
            this.props.findStories(params, this.props.sections);
            this.setState({
                search: search,
                section: section,
                searchFilterParams: localStorage.getItem('searchFilterParams') || JSON.stringify({})
            });
        } else {
            if(allstories === 'false') {
                //this.props.findStories({page: page, allstories: false, dataFrom: 'loadMore'});

                if(Data !== this.state.collectionStories && Data !== '') {
                    localStorage.setItem('section', '');
                    localStorage.setItem('page', '');
                    localStorage.setItem('searchFilterParams', JSON.stringify({}));
                    this.setState({collectionStories: Data}, () => {
                        sessionStorage.setItem('collectionStories', '');
                        this.props.setIdsInLoop(Data.data.map(a => a.canonicalurl))
                        if (localStorage.getItem('box') && localStorage.getItem('box') !== '') {
                            let storyId = '#story-' + localStorage.getItem('box');
                            //console.log(storyId)
                            if (storyId && document.querySelector(storyId)) {
                                document.querySelector('.main-wrapper').scrollTop = document.querySelector(storyId).offsetTop;
                                localStorage.setItem('box', '');
                                var uri = window.location.toString();
                                if (uri.indexOf("#") > 0) {
                                    var clean_uri = uri.substring(0, uri.indexOf("#"));
                                    window.history.replaceState({}, document.title, clean_uri);
                                }
                            }
                        }
                    });
                }

            }
        }

       // localStorage.setItem('search', '');

    }

    componentDidUpdate(prevProps, prevState, snapshot) {


        let search = localStorage.getItem('search') ? localStorage.getItem('search') : '';
        let section = localStorage.getItem('section') ? localStorage.getItem('section') : '';
        //let searchByFilter = localStorage.getItem('searchByFilter') ? JSON.parse(localStorage.getItem('searchByFilter')) : false;
        let searchFilterParams = localStorage.getItem('searchFilterParams') ? JSON.parse(localStorage.getItem('searchFilterParams')) : {};

        let filterSections = searchFilterParams.filterSections ? searchFilterParams.filterSections : section;
        let filterCategories = searchFilterParams.filterCategories ? searchFilterParams.filterCategories : "";
        //let page = localStorage.getItem('page') ? localStorage.getItem('page') : ''
        let allstories = localStorage.getItem('allstories') ? localStorage.getItem('allstories') : 'true';

        let params = {section: ''};
        if(filterSections !== '') {
            params.section = filterSections;
        }
        if(filterCategories !== '') {
            params.category = filterCategories;
        }
        if(searchFilterParams.startDate) {
            params.pdate = searchFilterParams.startDate + ',' + searchFilterParams.endDate;
        }
        if(search !== '') {
            params.search = search;
        }
        //console.log(allstories === 'false')
        //console.log(search +'!=='+ prevState.search)
        //console.log(section +'!=='+ prevState.section)
        //console.log(localStorage.getItem('searchFilterParams') +'!=='+ prevState.searchFilterParams)
        if(allstories === 'false'  && window.mode === 'normal') {
            //this.props.findStories({page: page, allstories: false, dataFrom: 'loadMore'});

        }
        else if(
            section !== prevState.section ||
            (this.props.sections !== prevProps.sections && this.props.sections.length > 0) ||
            prevState.searchFilterParams !== localStorage.getItem('searchFilterParams') ||
            search !== prevState.search
        ) {

            params.dataFrom = 'main';
            //console.log('######### componentDidUpdate');
            this.props.findStories(params, this.props.sections);

            this.setState({
                section: section,
                search: search,
                searchFilterParams: localStorage.getItem('searchFilterParams') || JSON.stringify({})
            });

            if(localStorage.getItem('section') === undefined || localStorage.getItem('section') === null) {
                localStorage.setItem('section', section);
            }
            if(localStorage.getItem('searchFilterParams') === undefined || localStorage.getItem('searchFilterParams') === null) {
                localStorage.setItem('searchFilterParams', JSON.stringify({}));
            }
        }

        if(this.props.stories.data !== prevProps.stories.data) {
            let Data = {};
            if(this.props.stories.dataFrom === 'loadMore') {
                //console.log('%%%%%%%%%%%%%%');
                //console.log(this.props.stories)
                Data = {
                    data: this.state.collectionStories.data.concat(this.props.stories.data),
                    count: this.props.stories.data.length,
                    totalitems: this.props.stories.total,
                    page: this.props.stories.page
                };
            } else {
                Data = {
                    data: this.props.stories.data,
                    count: this.props.stories.data.length,
                    totalitems: this.props.stories.total,
                    page: this.props.stories.page
                };
            }
            this.setState({collectionStories: Data}, () => {
                this.props.setIdsInLoop(Data.data.map(a => a.canonicalurl))
                if(localStorage.getItem('box') && localStorage.getItem('box') !== '') {
                    let storyId = '#story-' + localStorage.getItem('box');
                    //console.log(storyId)
                    if (storyId && document.querySelector(storyId)) {
                        document.querySelector('.main-wrapper').scrollTop = document.querySelector(storyId).offsetTop;
                        localStorage.setItem('box', '');
                        var uri = window.location.toString();
                        if (uri.indexOf("#") > 0) {
                            var clean_uri = uri.substring(0, uri.indexOf("#"));
                            window.history.replaceState({}, document.title, clean_uri);
                        }
                    }
                }
            });
        }

        /*if(prevProps.loggedIn === false && this.props.loggedIn === true) {
            this.props.findBookmarks({userId: this.props.user.userId});
        }*/
        if(this.props.bookmarks.data && this.props.bookmarks.data !== prevProps.bookmarks.data) {
            this.setState({
                bookmarks: this.props.bookmarks.data,
                bookmarksIds: this.props.bookmarks.data.map(a => a['story-id'])
            });
        }

    }
    componentDidMount() {

        /*if(this.props.scroll !== '') {
            document.querySelector('.main-wrapper').scrollTop = this.props.scroll
        }*/


    }
    render() {
        //console.log(this.state.collectionStories)
        //let stories = this.props.collectionStories.data;
        let data = this.state.collectionStories && this.state.collectionStories.data ? this.state.collectionStories.data : [];
        let totalitems = this.state.collectionStories && this.state.collectionStories.totalitems ? this.state.collectionStories.totalitems : 0;
        //let bookmarks = this.state.bookmarks;
        let bookmarksIds = this.state.bookmarksIds;


        //console.log(this.props.ads)
        //console.log(this.state.collectionStories)
        let boxAds = this.props.ads.box;
        let ud = localStorage.getItem('ud') ? JSON.parse(atob(localStorage.getItem('ud'))) : {};
        let expiresOn = new Date();
        let today = new Date(new Date().setHours(0, 0, 0));
        if(ud['expire on']) {
            let expiry = ud['expire on'].split('-');
            expiresOn = new Date(expiry[0], expiry[1]-1, expiry[2], 0, 0, 0, 0);
        }
        //console.log(data)
        //console.log(data.length)
        return (
            <React.Fragment>
                {data.length === 0 &&
                <div className="story-placeholder">
                    <p>Stories will update soon.</p>
                </div>
                }
                <InfiniteScrollPanel
                    dataLength={parseInt(data.length)}
                    fetchMoreData={this.fetchMoreData}
                    hasMore={totalitems !== data.length}
                    length={parseInt(data.length)}
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="main-wrapper"
                >
                {data.map((story, storyIndx) => {

                    let paidNews = (((story['section'] === 'Investment Insights' || story['section'] === 'Special Coverage') && (story['flags'].indexOf('Special Free') < 0)) && (this.props.loggedIn === false || (this.props.loggedIn === true && expiresOn.getTime() < today.getTime())));
                    //let newsLink = paidNews === true ? "/subscribe?refer=stories" : "article/" + story['_id'];

                    let newsLink;
                    if(this.props.loggedIn === true && expiresOn.getTime() < today.getTime()) {
                        newsLink = paidNews === true ? "/my-account?refer=stories" : "/stories/" + story['canonicalurl'] + '.html';
                    } else {
                        newsLink = paidNews === true ? "/subscribe?refer=stories" : "/stories/" + story['canonicalurl'] + '.html';
                    }
                    //console.log('expiresOn: ' + expiresOn)
                    //console.log('newsLink: ' + newsLink)
                    return(
                        <React.Fragment key={`story-cont-${story['uniqueid']}`}>
                    <div className="each-grid" key={`story-${storyIndx}`} id={`story-${story['uniqueid']}`}>

                        <div className="grid-description">
                            <div className="grid-description-header">
                                <p>{moment(new Date(story['publishdate'].replace(/-/g, "/"))).format('MMM D, YYYY')}</p>
                                <div className={`tag-type ${story['section'].replace(/\s+/g, '-').toLowerCase()}`}>{story['section']}</div>
                            </div>
                            {(story['attachment'] && story['attachment'] !== '') &&
                            <figure className="grid-image">
                                <a onClick={(e) => this.goToDetails(story['uniqueid'], paidNews, newsLink)}>

                                    <LazyLoadImage
                                        alt={`Lead Image`}
                                        src={story['attachment']}
                                        effect="opacity"/>
                                </a>
                            </figure>
                            }
                            <div className="grid-description-wrapper">
                                <div className="grid-description-content">
                                    <h5>
                                        <a onClick={(e) => this.goToDetails(story['uniqueid'], paidNews, newsLink)}>{story['heading']}</a>
                                    </h5>
                                    <p>

                                        <a onClick={(e) => this.goToDetails(story['uniqueid'], paidNews, newsLink)} dangerouslySetInnerHTML={{__html: story['shortdescription']}}></a>
                                    </p>

                                </div>
                            </div>
                            <div className="grid-description-footer">
                                <div className="grid-option">
                                    {this.props.loggedIn === false &&
                                    <a href={`/subscribe?refer=bookmark`}  className="bookmark"></a>
                                    }
                                    {this.props.loggedIn === true &&
                                    <a onClick={(e) => this.bookmarkAction(story)} className={`bookmark${bookmarksIds.indexOf(story['uniqueid']) >= 0 ? ' active' : ''}`}></a>
                                    }

                                    <div className="share-story">
                                        <button className="share" onClick={(e) => this.showShare(story['_id'])}/>
                                        {this.state.storyToShare === story['_id'] &&
                                        <Share
                                            url={`${window.location}stories/${story['canonicalurl']}.html`}
                                            title={story['heading']}
                                            description={story['shortdescription']}
                                            closeShare={this.showShare}
                                        />
                                        }
                                    </div>
                                </div>
                                <a onClick={(e) => this.goToDetails(story['uniqueid'], paidNews, newsLink)}>READ FULL STORY</a>

                            </div>
                        </div>
                    </div>
                    {((storyIndx + 1) % 6 === 0 && boxAds && boxAds[parseInt((storyIndx+1) / 6)-1]) &&
                        <div className="mob-ad hide-lg">
                            {(boxAds[parseInt((storyIndx+1) / 6)-1]['ad_type'] === 'Display' || boxAds[parseInt((storyIndx+1) / 6)-1]['ad_type'] === 'Video') &&
                            <a target="_blank"
                               href={boxAds[parseInt((storyIndx+1) / 6)-1]['url-display-ad'] || ""} rel="noreferrer"
                            >
                                {boxAds[parseInt((storyIndx+1) / 6)-1]['ad_type'] === 'Display' &&
                                <LazyLoadImage
                                    alt={`Ad Image`}
                                    src={IMG_PATH + boxAds[parseInt((storyIndx+1) / 6)-1]['display_ad_material']}
                                    effect="opacity"/>
                                    /*<img src={nodeSrc}/>*/
                                }
                                {boxAds[parseInt((storyIndx+1) / 6)-1]['ad_type'] === 'Video' &&
                                <video src={VIDEO_PATH + boxAds[parseInt((storyIndx+1) / 6)-1]['video_ad_material']} autoPlay={true}/>
                                }
                            </a>
                            }
                        </div>

                    }
                        </React.Fragment>
                    )
                },)}
                </InfiniteScrollPanel>

            </React.Fragment>
        )
    }
}


function mapStateToProps(state) {
    return {
        userAccount: actionReducers.getUserAccount(state),
        errorMessage: actionReducers.getErrorMessage(state),
        loader: actionReducers.getLoader(state),
        stories: actionReducers.getStories(state),
        bookmarks: actionReducers.getBookmarks(state),
    }
}
export default connect(mapStateToProps, {
    signUp: ActionCreators.doSignup,
    userLogin: ActionCreators.userLogin,
    findStories: ActionCreators.findStories,
    addBookmarks: ActionCreators.addBookmarks,
    findBookmarks: ActionCreators.findBookmarks,
    deleteBookmarks: ActionCreators.deleteBookmarks
})(Home);
