import React, { Component } from "react";
import { FB_KEY} from '../../constants/index';
import {
    FacebookShareButton,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
    LinkedinShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,
    LinkedinIcon
} from "react-share";

class Share extends Component {
    constructor(props) {
        super(props);
        this.state = {
            copy: false,
        };
        this.handleCopy = this.handleCopy.bind(this);
        this.shareOnFacebook = this.shareOnFacebook.bind(this);
    }
    handleCopy() {
        var copyText = document.getElementById("share-url");
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/
        document.execCommand("copy");
        this.setState({ copy: true });
        let obj = this;
        setTimeout(() => {
            obj.setState({ copy: false });
        }, 2000);
    }
    shareOnFacebook() {
        let shareUrl = window.location;
        window.open("https://www.facebook.com/sharer/sharer.php?u=" + shareUrl, "pop", "width=600, height=400, scrollbars=no");
        return false;
    }

    render() {
        let shareUrl = this.props.url;
        let title = "";
        let body = "Check out this site ";
        return (
            <div className="share-container">
                <div className="inner-area white-panel">
                    <button onClick={(e) => this.props.closeShare("")}></button>
                    <div className="white-pd-block">

                            <FacebookShareButton
                                url={shareUrl}
                                quote={title}
                                className="share-button"
                            >
                                <FacebookIcon size={20} round />
                            </FacebookShareButton>
                            <FacebookMessengerShareButton
                                url={shareUrl}
                                appId={FB_KEY}
                                className="share-button"
                            >
                                <FacebookMessengerIcon size={20} round />
                            </FacebookMessengerShareButton>
                            {/*<span onClick={this.shareOnFacebook}>
                                <FacebookIcon size={42} round />
                            </span>*/}

                            <TwitterShareButton
                                url={shareUrl}
                                title={title}
                                className="share-button"
                            >
                                <TwitterIcon size={20} round />
                            </TwitterShareButton>
                            <WhatsappShareButton
                                url={shareUrl}
                                title={title}
                                separator=" "
                                className="share-button"
                            >
                                <WhatsappIcon size={20} round />
                            </WhatsappShareButton>
                            <EmailShareButton
                                url={shareUrl}
                                subject={title}
                                body={body}
                                className="share-button"
                            >
                                <EmailIcon size={20} round />
                            </EmailShareButton>
                            <LinkedinShareButton
                                url="https://hedgeohari.bsynapse.com/stories/rcom-hits-lower-circuit.html"
                                title="testing title"
                                summary="testing summary"
                                className="share-button"
                            >
                                <LinkedinIcon size={20} round />
                            </LinkedinShareButton>



                    </div>
                </div>
            </div>
        );
    }
}

export default Share;
