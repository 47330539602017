import config from './config.json';
var DATA;
let host = window.location.hostname;
console.log(host)
//DATA = config['localhost'];

if(host === 'localhost' || host === '127.0.0.1' || host === 'hedgeohari.labsls.com') {
    DATA = config['localhost'];
} else if(host === 'hedgeohari.bsynapse.com') {
    DATA = config['dev'];
} else if(host === 'hedgeohariprod.bsynapse.com' || host === 'hedgeohari.com' || host === 'mobile.hedgeohari.com' ) {
    DATA = config['production'];
}
console.log(DATA)
export const ES_INDEX = DATA.ESINDEX
export const API_PATH = DATA.APIPATH
export const DEV_API_PATH = DATA.DEVAPIPATH
export const IMG_PATH = DATA.IMGPATH
export const VIDEO_PATH = DATA.VIDEOPATH
export const GOOGLE_KEY = DATA.GOOGLEKEY
export const FB_KEY = DATA.FBKEY
export const RAZORPAY_KEY_ID = DATA.RAZORPAYKEYID
export const RAZORPAY_KEY_SECRET = DATA.RAZORPAYKEYSECRET
export const HOME_PAGE = DATA.HOMEPAGE

