import React from 'react';

class LoaderAnimation extends React.Component{
	constructor(props) {
		super(props)
		this.state = {
			
			}
		}
	


	render() {
		return (
			<div className="loader-wrapper transparent">
			<div className="loader-anim" style={{width: 60, height: 60}} >

				<svg style={{margin: 'auto', background: 'none', display: 'block', shapeRendering: 'auto'}} width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
					<circle cx="50" cy="50" fill="none" stroke="#903e97" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
						<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
					</circle>
				</svg>
			</div>
			</div>
			)
				
		}
	}
export default LoaderAnimation;