import React, { Component } from "react";
import {Link, Redirect} from 'react-router-dom';
import * as actionReducers from "../../reducers";
import {connect} from "react-redux";
import * as ActionCreators from "../../actions";
import moment from "moment";

class MyAccount extends Component {
    constructor(props) {
        super();
        this.state = {
            edit: false,
            name: '',
            mobile: '',
            profession: '',
            user: {},
            error: {
                name: '',
                mobile: '',
                profession: ''
            },
            paymentInfo: {}
        }
        this.toggleEditProfile = this.toggleEditProfile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.goToPage = this.goToPage.bind(this);
    }
    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }
    updateProfile() {
        console.log(this.state)
        let errors = 0;
        let error = {
            name: "",
            mobile: ""
        };
        if (this.state.name === "") {
            error["name"] = "Enter name";
            errors++;
        }

        if (this.state.mobile === "") {
            error["mobile"] = "Enter mobile";
            errors++;
        }
        if (this.state.profession === "") {
            error["profession"] = "Enter profession";
            errors++;
        }
        if (errors === 0) {

            let user = this.state.user;
            user.name = this.state.name;
            user.mobile = this.state.mobile;
            user.profession = this.state.profession;
            this.props.updateProfile(user);

            //this.toggleEditProfile();
        }
        this.setState({error: error});

    }
    toggleEditProfile() {
        this.setState({edit: !this.state.edit});
    }
    goToPage(id, plan) {
        if(id === 'renew') {
            this.props.setDataToRenew(plan, 'payment', localStorage.getItem('ud') ? JSON.parse(atob(localStorage.getItem('ud'))) : {});
        }

    }
    componentDidMount() {
        let user = JSON.parse(atob(localStorage.getItem('ud')));
        this.setState({name: user.name, mobile: user.mobile, profession: user.profession, user: user});
        if(this.props.loggedIn === true) {
            this.props.findSubscriptionPlans();
        }
        let refer = new URL(window.location.href).searchParams.get("refer");
        let showModal = (refer === 'stories' || refer === 'bookmark') ? true : false;
        let message = (refer === 'stories') ? 'Please renew your subscription to read the full story. Hedge Ohari finance journal has daily updates of insightful stories on financial markets and achieving financial freedom.' : 'Please subscribe to book mark your favourite stories. Hedge Ohari finance journal has daily updates of insightful stories on financial markets and achieving financial freedom.'
        if(showModal === true) {
            this.props.toggleModal(
                showModal,
                'Renew Subscription',
                message,
                this.props.toggleModal,
                false,
                this.props.toggleModal,
                'md'
            );
        }

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        /*if(prevProps.subscriber.data !== this.props.subscriber.data &&
            this.props.subscriber.success === true && this.props.subscriber.data.userId) {

            this.props.toggleModal(
                true,
                'Subscription',
                'You have successfully completed subscription of Hedge Ohari',
                this.props.toggleModal,
                false,
                this.props.toggleModal,
                'md'
            );
            this.props.setPlanTab('personal');
        }*/

        if(prevProps.profileUpdate.success === false && this.props.profileUpdate.success === true) {
            this.setState({edit: false});
        }
        let refer = new URL(window.location.href).searchParams.get("refer");
        let _id = localStorage.getItem('_pId') ? window.atob(localStorage.getItem('_pId')) : '';
        let _oId = localStorage.getItem('_oId') ? window.atob(localStorage.getItem('_oId')) : '';
        let user = localStorage.getItem('ud') ? JSON.parse(atob(localStorage.getItem('ud'))) : {};
        let showModal = (refer === 'stories' || refer === 'bookmark') ? true : false;
        let message = (refer === 'stories') ? 'Please renew your subscription to read the full story. Hedge Ohari finance journal has daily updates of insightful stories on financial markets and achieving financial freedom.' : 'Please subscribe to book mark your favourite stories. Hedge Ohari finance journal has daily updates of insightful stories on financial markets and achieving financial freedom.'
        /*if(showModal === true) {
            this.props.toggleModal(
                showModal,
                'Renew Subscription',
                message,
                this.props.toggleModal,
                false,
                this.props.toggleModal,
                'md'
            );
        }*/
        //console.log('_id' + _id)
        //console.log('this.state.paymentInfo.status' + this.state.paymentInfo.status)
        if(_id !== '' && !this.state.paymentInfo.status) {

            this.props.ohariPaymentInfo({paymentId: _id, orderId: _oId, name: user.name || ''});
        }
        //console.log(this.props.orderPaymentInfo.data);
        //console.log(this.state.paymentInfo);
        if (this.props.orderPaymentInfo.data && this.state.paymentInfo && this.props.orderPaymentInfo.data.id !== this.state.paymentInfo.id) {
            this.setState({paymentInfo: this.props.orderPaymentInfo.data});
        }

    }

    render() {
        let user = JSON.parse(atob(localStorage.getItem('ud')));
        if(!user.userId) {
            return <Redirect to="/sign-in" />
        }
        let plans = this.props.subscriptionPlans.data;

        let startDate = moment(new Date(user['start date'])).format('MMM D, YYYY');
        let endDate = moment(new Date(user['expire on'])).format('MMM D, YYYY');
        let expiry = moment(new Date(user['expire on']));
        let today = moment(new Date());
        let daysForExpiry = expiry.diff(today, 'days') + 1;
        let userPlan = plans.filter((a) => (a['text_plan'] === user['Subscription Plan']))[0];

        return (
            <React.Fragment>

                <div className="anchor-links">
                    <Link onClick={(e) => this.props.changeSection('')}>Home</Link>  /  <Link className="current-page">My Account</Link>
                </div>

                <div className="boiler-panel">
                    <div className="boiler-banner hide">
                        <h5>My Account</h5>
                    </div>
                    <div className="boiler-content">
                       <div className="my-account-block">
                           {this.state.edit === false &&
                               <React.Fragment>
                                   <div className="account-header">
                                       <div className="account-thumb">
                                           <h5>{user.name.charAt(0)}</h5>
                                       </div>
                                       <div className="account-details">
                                           <h5>{user.name || ''}</h5>
                                           <p>{user.email || ''}</p>
                                           <div className="profile-edit-block">
                                               <button className="border-btn" onClick={this.toggleEditProfile}>Edit Profile</button>
                                           </div>
                                       </div>
                                   </div>


                                    {/*<div className="account-body">*/}
                                    {/*    <div className="small-header">*/}
                                    {/*        <h5>My Subscription</h5>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="account-inner-content">*/}
                                    {/*        {user['subscriber_ id'] &&*/}
                                    {/*        <p>Subscription ID: <span className="font-bold">{user['subscriber_ id']}</span></p>*/}
                                    {/*        }*/}
                                    {/*        {user['status'] &&*/}
                                    {/*        <p>Status: <span className="font-bold">{user['status']}</span></p>*/}
                                    {/*        }*/}
                                    {/*        {user['Subscription Plan'] &&*/}
                                    {/*        <p>Subscription Plan: <span className="font-bold">{user['Subscription Plan']}</span></p>*/}
                                    {/*        }*/}
                                    {/*        <p>Subscription Period: <span className="font-bold">{startDate} - {endDate}</span></p>*/}
                                    {/*        <p>Days for renewal: <span className="font-bold">{daysForExpiry}</span></p>*/}
                                    {/*        <div className="button-group">*/}
                                    {/*           /!* <button className="each-btn upgrade">Upgrade Subscription</button>*!/*/}
                                    {/*            {daysForExpiry <= 30 &&*/}
                                    {/*                <React.Fragment>*/}
                                    {/*                   /!* <Link style={{'lineHeight': '40px'}} to="plan?refer=renew" className="each-btn renew" onClick={(e) => this.goToPage('renew', userPlan)}>Renew Subscription</Link>*!/*/}
                                    {/*                    <Link style={{'lineHeight': '40px'}} to="subscribe?refer=upgrade" className="each-btn renew" >Renew Subscription</Link>*/}
                                    {/*                    /!*{user['Subscription Plan'] !== 'Five Year Plan' &&*/}
                                    {/*                    <Link style={{'lineHeight': '40px'}} to="subscribe?refer=upgrade" className="each-btn upgrade">Upgrade*/}
                                    {/*                        Subscription</Link>*/}
                                    {/*                    }*!/*/}
                                    {/*                </React.Fragment>*/}
                                    {/*            }*/}
                                    {/*            /!*<button className="each-btn cancel">Cancel Subscription</button>*!/*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    {*/}
                                    {/*        this.state.paymentInfo.status &&*/}
                                    {/*        <React.Fragment>*/}
                                    {/*        <div className="small-header">*/}
                                    {/*            <h5>Payment Status</h5>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="account-inner-content">*/}
                                    
                                    
                                    {/*        <p>Order Number: <span className="font-bold">{this.state.paymentInfo.order_id}</span></p>*/}
                                    {/*        <p>Amount: <span className="font-bold">Rs.{this.state.paymentInfo.amount / 100}/-</span></p>*/}
                                    {/*        <p>Status: <span className="font-bold">Payment Received</span></p>*/}
                                    
                                    
                                    {/*        </div>*/}
                                    {/*        </React.Fragment>*/}
                                    {/*    }*/}
                                    {/*    /!*<div className="small-header">*/}
                                    {/*        <h5>Manage Newsletters</h5>*/}
                                    {/*    </div>*/}
                                    
                                    {/*    <div className="account-inner-content">*/}
                                    {/*        <div className="checkbox-wrapper">*/}
                                    {/*        <div class="checkbox">*/}
                                    {/*            <label>Enable to get uptodate News letters*/}
                                    {/*                <input type="checkbox"/>*/}
                                    {/*                <div class="control-indicator"></div>*/}
                                    {/*            </label>*/}
                                    {/*        </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*!/*/}
                                    {/*</div>*/}
                               </React.Fragment>
                           }
                           {this.state.edit === true &&
                           <React.Fragment>
                               <div className="form-container">

                                   <div className="each-input-field">
                                       <label>Full Name</label>
                                       <input type="text" name="name" value={this.state.name}
                                              onChange={this.handleChange}/>
                                       <div
                                           className={`error-field${this.state.error.name === '' ? ' hide' : ''}`}>{this.state.error.name}</div>
                                   </div>

                                   <div className="each-input-field">
                                       <label>Mobile Number</label>
                                       <input type="text" name="mobile" value={this.state.mobile}
                                              onChange={this.handleChange}/>
                                       <div
                                           className={`error-field${this.state.error.mobile === '' ? ' hide' : ''}`}>{this.state.error.mobile}</div>

                                   </div>
                                   <div className="each-input-field">
                                       <label>Profession</label>
                                       <div className="select-box">
                                           <select name="profession" value={this.state.profession}
                                                   onChange={this.handleChange}>
                                               <option>Select Profession</option>
                                               <option>Business</option>
                                               <option>Self Employed</option>
                                               <option>Employed - Private</option>
                                               <option>Employed - Government</option>
                                               <option>Agriculture</option>
                                               <option>Student</option>
                                               <option>Homemaker</option>
                                               <option>Other</option>
                                           </select>
                                       </div>
                                       <div
                                           className={`error-field${this.state.error.profession === '' ? ' hide' : ''}`}>{this.state.error.profession}</div>
                                       {this.state.error.profession === '' &&
                                       this.props.errorMessage.status === true &&
                                       this.props.errorMessage.element === "UPDATE_PROFILE" && (
                                           <div className={`error-field`}>
                                               {this.props.errorMessage.message}
                                           </div>
                                       )}
                                   </div>
                                   <div className="form-submit-block">
                                       <button className="border-btn grey" onClick={(e) => this.toggleEditProfile()}>Back To Profile</button>
                                       <button className="border-btn" onClick={(e) => this.updateProfile()}>UPDATE</button>
                                   </div>
                               </div>
                           </React.Fragment>
                           }
                       </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        //loader: actionReducers.getLoader(state),
        subscriptionPlans: actionReducers.getSubscriptionPlans(state),
        orderPaymentInfo: actionReducers.getPaymentInfo(state)
    }
}
export default connect(mapStateToProps, {
    findSubscriptionPlans: ActionCreators.findSubscriptionPlans,
    ohariPaymentInfo: ActionCreators.ohariPaymentInfo
})(MyAccount);
