import React, { Component } from "react";



import * as actionReducers from "../../reducers";
import {connect} from "react-redux";
import * as ActionCreators from "../../actions";
import {getUrlSegment} from "../../utilities/CustomFunctions";
import {HOME_PAGE} from "../../constants";

class ResetPassword extends Component {
    constructor(props) {
        super();


    }





    render() {





        //console.log(window.atob(url1))

        return (
            <React.Fragment>

                <div className="main-wrapper">

                    <section className="notFound">
                        <div className="card"> 
                            <p className="largeText">404</p>                           
                            <p>Page Not Found</p>
                        </div>
                    </section>

                </div>


            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        loader: actionReducers.getLoader(state),
        resetPasswordMessage: actionReducers.getResetPassword(state),
    }
}
export default connect(mapStateToProps, {
    resetPassword: ActionCreators.resetPassword
})(ResetPassword);
