import React, {Component} from 'react';
import {Scrollbars} from "react-custom-scrollbars";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import history from "../../history";

class Filters extends Component {
    constructor (props) {
        super(props);
        this.state = {
            filterTab: false,
            startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 2)),
            endDate: new Date(),
            filterSections: [],
            filterCategories: [],
            showFilter: true,
            dateOpened: false
        }
        this.setFilterSections = this.setFilterSections.bind(this);
        this.setFilterCategories = this.setFilterCategories.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.changeSection = this.changeSection.bind(this);
        this.filterNews = this.filterNews.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.dateClose = this.dateClose.bind(this);
        this.dateOpen = this.dateOpen.bind(this);
        this.myRef = React.createRef()
    }

    filterToggler = () => {
        let filterTab = this.state.filterTab;
        this.setState({ filterTab: !filterTab});
        this.props.filterToggler(!filterTab);
    }
    dateOpen(e) {
        this.setState({dateOpened: true});
    }
    dateClose(e) {
        this.setState({dateOpened: false});
    }
    setStartDate(date) {
        this.setState({startDate: date})
        //this.setState({dateOpened: false});

    }
    setEndDate(date) {
        this.setState({endDate: date})
        //this.setState({dateOpened: false});
        //this.setState({dateOpened: false});

    }
    toggleFilter() {
        this.setState({showFilter: !this.state.showFilter});
    }
    setFilterSections(e) {
        let filterSections = this.state.filterSections;

        if(e.target.checked === true) {
            filterSections.push(e.target.value);
        } else {
            filterSections.splice(filterSections.indexOf(e.target.value), 1);
        }
        this.setState({filterSections: filterSections});
    }
    setFilterCategories(e) {
        let filterCategories = this.state.filterCategories;

        if(e.target.checked === true) {
            filterCategories.push(e.target.value);
        } else {
            filterCategories.splice(filterCategories.indexOf(e.target.value), 1);
        }
        this.setState({filterCategories: filterCategories});
    }
    changeSection(section) {
        this.setState({
            startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
            endDate: new Date(),
            filterSections: [],
            filterCategories: [],
        })
        this.props.changeSection(section);
    }
    filterNews(params) {
        this.props.filterNews(params);
        this.setState({filterTab: false});
    }
    goToPage(url) {
        history.push(url);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.filterTab !== this.props.filterTab) {
            this.setState({filterTab: this.props.filterTab});
            if(this.props.filterTab === true) {
                this.setState({showFilter: true})
            }

        }
        if(prevProps.page !== this.props.page) {

            if(this.props.page !== '' && this.props.filterTab === false) {
                this.setState({showFilter: false})
            } else {
                this.setState({showFilter: true})
            }
        }

    }
    componentDidMount() {
        if(document.location.pathname !== '/' && this.props.filterTab === false) {
            this.setState({showFilter: false})
        } else {
            this.setState({showFilter: true})
        }
        let obj = this;
        if(window.screen.width < 1024) {
            this.syncTmr = setInterval(function () {
                if (document.getElementById('li-active')) {
                    document.getElementById('li-active').scrollIntoView();
                    clearInterval(obj.syncTmr);
                }
            }, 250);
        }

        let searchFilterParams = localStorage.getItem('searchFilterParams') ? JSON.parse(localStorage.getItem('searchFilterParams')) : {};
        if(searchFilterParams.filterCategories && searchFilterParams.filterCategories.split(',') !== this.state.filterCategories) {
            this.setState({filterCategories: searchFilterParams.filterCategories === "" ? [] : searchFilterParams.filterCategories.split(',')});
        }
        if(searchFilterParams.filterSections && searchFilterParams.filterSections.split(',') !== this.state.filterSections) {
            this.setState({filterSections: searchFilterParams.filterSections === "" ? [] : searchFilterParams.filterSections.split(',')});
        }
        if(searchFilterParams.startDate || searchFilterParams.endDate) {
            let sDt = searchFilterParams.startDate.split('-');
            let startDate = new Date(sDt[0], sDt[1]-1, sDt[2], 0, 0, 0, 0);
            let eDt = searchFilterParams.endDate.split('-');
            let endDate = new Date(eDt[0], eDt[1]-1, eDt[2], 0, 0, 0, 0);
            if (startDate && startDate !== this.state.startDate) {
                this.setState({startDate: startDate});
            }
            if (endDate && endDate !== this.state.endDate) {
                this.setState({endDate: endDate});
            }
        }
    }

    render() {

        let startDate = this.state.startDate;
        let m = (parseInt(startDate.getMonth()) + 1) < 10 ? '0' + (parseInt(startDate.getMonth()) + 1) : (parseInt(startDate.getMonth()) + 1);
        let d = 1;
        let start = startDate.getFullYear() + '-' + m + '-' + d;
        let endDate = this.state.endDate;

        let m2 = (parseInt(endDate.getMonth()) + 1) < 10 ? '0' + (parseInt(endDate.getMonth()) + 1) : (parseInt(endDate.getMonth()) + 1);
        let d2 = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate();;
        let end = endDate.getFullYear() + '-' + m2 + '-' + d2;
        let sectionSlctd = localStorage.getItem('section') ? localStorage.getItem('section') : '';
        let allstories = localStorage.getItem('allstories') ? localStorage.getItem('allstories') : 'true';

        return(
            <aside className="section-left">
                <Scrollbars autoHide autoHideTimeout={1000}>
                    <ul className="tab-menu" id="section-tab-menu" ref={this.ulRef}>
                        <li className={`${(sectionSlctd === '' && document.location.pathname === "/" && this.state.filterCategories.length === 0 && this.state.filterSections.length === 0 && allstories === 'true') ? 'active' : ''}`}
                            ref={`${(sectionSlctd === '' && document.location.pathname !== "/bookmarks") ? this.myRef : ''}`}
                        onClick={(e) => this.changeSection('')}
                        id={`${(sectionSlctd === '' && document.location.pathname !== "/bookmarks") ? 'li-active' : ''}`}>
                            <nav className="all-stories">Home</nav>
                        </li>
                        {this.props.sections.map((section, sectionIndx) =>
                            <li
                                key={`story-section-${sectionIndx}`}
                                className={`${sectionSlctd === section.text_content_sections[0] ? 'active' : ''}`}
                                ref={`${sectionSlctd === section.text_content_sections[0] ? this.myRef : ''}`}
                                onClick={(e) => this.changeSection(section.text_content_sections[0])}
                            id={`${sectionSlctd === section.text_content_sections[0] ? 'li-active' : ''}`}>
                                <nav className={`${section.text_content_sections[0].replace(/\s+/g, '-').toLowerCase()}`}>{section.text_content_sections[0]}</nav>
                            </li>
                        )}
                        {this.props.loggedIn === true &&
                        <li className={`${(document.location.pathname === "/bookmarks") ? 'active' : ''} hide-sm`}>
                            <nav class="story-bookmark"><a href="/bookmarks">BOOKMARKS</a></nav>
                        </li>
                        }
                        {/*<li>
                            <nav className="invest-insight">INVESTMENT INSIGHTS</nav>
                        </li>
                        <li>
                            <nav className="special-cov">SPECIAL COVERAGE</nav>
                        </li>
                        <li>
                            <nav className="h-highlight">HEDGE HIGHLIGHTS</nav>
                        </li>*/}
                    </ul>

                    <div className="social-media-wrapper">
                        <div className="social-header">
                            <h5>Visit Hedge at</h5>
                        </div>
                        <ul className="social-media-block">
                            <li>
                                <a className="web-ico" href={this.props.socialLinks['Website']} target="_blank" rel="noreferrer">hedgeequities.com</a>
                            </li>
                            <li>
                                <a className="yt-ico" href={this.props.socialLinks['YouTube Channel']} target="_blank" rel="noreferrer">Youtube</a>
                            </li>
                            <li>
                                <a className="fb-ico" href={this.props.socialLinks['Facebook']} target="_blank" rel="noreferrer">Facebook</a>
                            </li>
                            <li>
                                <a className="tw-ico" href={this.props.socialLinks['Twitter']} target="_blank" rel="noreferrer">Twitter</a>
                            </li>
                            <li>
                                <a className="ig-ico" href={this.props.socialLinks['Instagram']} target="_blank" rel="noreferrer">Instagram</a>
                            </li>
                            <li>
                                <a className="ln-ico" href={this.props.socialLinks['LinkedIn']} target="_blank" rel="noreferrer">LinkedIn</a>
                            </li>
                        </ul>
                    </div>

                    

                    <div className="filter-wrapper">

                        <div className="filter-mob-panel">
                            <button className="filter-btn" onClick={() => this.filterToggler()}>FILTERS</button>

                            <div className="filter-tag-wrapper hide">
                                <div className="each-filter-tag">OCT ’20- JAN ‘21 <button className="tag-close"></button></div>
                                <div className="each-filter-tag">INVESTING <button className="tag-close"></button></div>
                            </div>
                        </div>

                        <div className={this.state.filterTab ? `filter-tab fly-in` : `filter-tab`}>

                            <div className="filter-header" onClick={this.toggleFilter}>
                                <h5>FIND STORIES</h5>
                                <p>You can find stories by published month, sections and categories.</p>
                            </div>

                            <button className="mob-close" onClick={() => this.filterToggler()}></button>

                            <div className={`filter-panel${this.state.showFilter === true ? ' fly-in' : ''}`}>

                                <div className="filter-panel-body">
                                    <div className="filter-panel-body-inner">
                                        <div className="each-filter-panel">
                                            <h5>PUBLISHING DATE RANGE</h5>
                                            <div className="date-range-block">
                                                <div className="date-picker">
                                                    {/*<input type="text" placeholder="Start" value="START"/>*/}
                                                    <DatePicker
                                                        selected={this.state.startDate}
                                                        onChange={date => this.setStartDate(date)}
                                                        dateFormat="MM/yyyy"
                                                        showMonthYearPicker
                                                        showTwoColumnMonthYearPicker
                                                        onCalendarOpen={this.dateOpen}
                                                        onCalendarClose={this.dateClose}
                                                    />
                                                </div>
                                                <div className="date-picker">
                                                    {/*<input type="text" placeholder="End"  value="END"/>*/}
                                                    <DatePicker
                                                        selected={this.state.endDate}
                                                        onChange={date => this.setEndDate(date)}
                                                        dateFormat="MM/yyyy"
                                                        showMonthYearPicker
                                                        showTwoColumnMonthYearPicker
                                                        onCalendarOpen={this.dateOpen}
                                                        onCalendarClose={this.dateClose}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`each-filter-panel${this.state.dateOpened === true ? ' overlay-hidden' : ''}`}>
                                            <h5>SECTION</h5>
                                            <div className="checkbox-wrapper">
                                                {this.props.sections.map((section, sectionIndx) =>
                                                <div className={`checkbox`} key={`filter-section-${sectionIndx}`}>
                                                    <label>{section.text_content_sections[0]}
                                                        <input
                                                            type="checkbox"
                                                            onChange={(e) =>this.setFilterSections(e)}
                                                            value={section.text_content_sections[0]}
                                                            checked={(this.state.filterSections.indexOf(section.text_content_sections[0]) > -1) ? 'checked' : ''}
                                                        />
                                                        <div className="control-indicator"></div>
                                                    </label>
                                                </div>
                                                )}

                                            </div>
                                        </div>

                                        <div className={`each-filter-panel${this.state.dateOpened === true ? ' overlay-hidden' : ''}`}>
                                            <h5>CATEGORY/TAGS</h5>
                                            <div className="checkbox-wrapper">
                                                {this.props.categories.map((category, cateIndx) =>
                                                <div className="checkbox"  key={`filter-category-${cateIndx}`}>
                                                    <label>{category.Category}
                                                        <input type="checkbox"
                                                        value={category.Category}
                                                        onChange={(e) => this.setFilterCategories(e)}
                                                        checked={(this.state.filterCategories.indexOf(category.Category) > -1 ) ? 'checked' : ''}/>
                                                        <div className="control-indicator"></div>
                                                    </label>
                                                </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="filter-bottom">
                                    <button className="border-btn"
                                            onClick={(e) =>
                                                this.filterNews({
                                                        startDate: start,
                                                        endDate: end,
                                                        filterSections: this.state.filterSections.join(','),
                                                        filterCategories: this.state.filterCategories.join(',')
                                                    }
                                                    )}>
                                        APPLY
                                    </button>
                                </div>

                            </div>

                        </div>
                    </div>                    
                    
                </Scrollbars>
            </aside>
        );
    }

    executeScroll = () => { console.log(this.myRef.current);this.myRef.current.scrollIntoView()}
}
export default Filters;
