import { combineReducers } from 'redux';


const UserAccount = (state = {user: {}}, action) => {
    switch(action.type){
        case "LOGIN_USER":
            return {
                ...state,
                user: action.data,
            }

        default:
            return state;
    }
}


const ErrorMessage = (state = {status:false, element:'', message:''}, action) => {
    switch(action.type){
        case "ERROR_MESSAGE":
            return {
                ...state,
                status: action.status,
                element: action.element,
                message: action.message
            }

        default:
            return state;
    }
}


const Loader = (state = {status:false, element:''}, action) => {
    switch(action.type){
        case "UPDATE_LOADER":
            return {
                ...state,
                status: action.status,
                element: action.element
            }

        default:
            return state;
    }
}
const Stories = (state = {data: [], total: 0, dataFrom: 'main', page: 1}, action) => {
    switch(action.type){
        case "STORIES":
            return {
                ...state,
                data: action.data,
                total: action.total,
                dataFrom: action.dataFrom,
                page: action.page
            }

        default:
            return state;
    }
}
const Sections = (state = {data: []}, action) => {
    switch(action.type){
        case "SECTIONS":
            return {
                ...state,
                data: action.data
            }

        default:
            return state;
    }
}
const Categories = (state = {data: []}, action) => {
    switch(action.type){
        case "CATEGORIES":
            return {
                ...state,
                data: action.data
            }

        default:
            return state;
    }
}
const StoryDetails = (state = {data: []}, action) => {
    switch(action.type){
        case "STORY_DETAILS":
            return {
                ...state,
                data: action.data
            }

        default:
            return state;
    }
}
const SubscriptionPlans = (state = {data: []}, action) => {
    switch(action.type){
        case "SUBSCRIPTION_PLANS":
            return {
                ...state,
                data: action.data
            }

        default:
            return state;
    }
}
const PageContents = (state = {data: []}, action) => {
    switch(action.type){
        case "PAGE_CONTENTS":
            return {
                ...state,
                data: action.data
            }

        default:
            return state;
    }
}
const Ads = (state = {solo: [], box: []}, action) => {
    switch(action.type){
        case "ADS":
            return {
                ...state,
                solo: action.solo,
                box: action.box
            }

        default:
            return state;
    }
}
const ContactForm = (state = {message: ''}, action) => {
    switch(action.type){
        case "CONTACT_FORM":
            return {
                ...state,
                message: action.message
            }

        default:
            return state;
    }
}
const SocialLinks = (state = {data: []}, action) => {
    switch(action.type){
        case "SOCIAL_LINKS":
            return {
                ...state,
                data: action.data
            }

        default:
            return state;
    }
}
const OTPSend = (state = {success: false, message: ''}, action) => {
    switch(action.type){
        case "SEND_OTP":
            return {
                ...state,
                success: action.success,
                message: action.message
            }

        default:
            return state;
    }
}
const OTPverified = (state = {success: false, message: ''}, action) => {
    switch(action.type){
        case "VERIFY_OTP":
            return {
                ...state,
                success: action.success,
                message: action.message
            }

        default:
            return state;
    }
}
const Subscriber = (state = {success: false, data: {}, message: ''}, action) => {
    switch(action.type){
        case "ADD_SUBSCRIBER":
            return {
                ...state,
                success: action.success,
                data: action.data,
                message: action.message
            }

        default:
            return state;
    }
}
const ProfileUpdate = (state = {success: false}, action) => {
    switch(action.type){
        case "UPDATE_PROFILE":
            return {
                ...state,
                success: action.success,
                data: action.data
            }

        default:
            return state;
    }
}
const ContinueWithEmail = (state = {success: false, data: {}, message: ''}, action) => {
    switch(action.type){
        case "EXISTS_SUBSCRIBER":
            return {
                ...state,
                success: action.success,
                data: action.data,
                message: action.message
            }

        default:
            return state;
    }
}
const Bookmarks = (state = {data: []}, action) => {
    switch(action.type){
        case "BOOKMARKS":
            return {
                ...state,
                data: action.data
            }

        default:
            return state;
    }
}
const BookmarkStories = (state = {data: []}, action) => {
    switch(action.type){
        case "BOOKMARK_STORIES":
            return {
                ...state,
                data: action.data,
                dataFrom: action.dataFrom
            }

        default:
            return state;
    }
}
const ForgotPassword = (state = {message: ''}, action) => {
    switch(action.type){
        case "FORGOT_PASSWORD":
            return {
                ...state,
                message: action.message
            }

        default:
            return state;
    }
}
const ResetPassword = (state = {message: ''}, action) => {
    switch(action.type){
        case "RESET_PASSWORD":
            return {
                ...state,
                message: action.message
            }

        default:
            return state;
    }
}
const PlaceOrder = (state = {orderId: ''}, action) => {
    switch(action.type){
        case "PLACE_ORDER":
            return {
                ...state,
                orderId: action.id
            }

        default:
            return state;
    }
}
const PaymentInfo = (state = {data: {}}, action) => {
    switch(action.type){
        case "PAYMENT_INFO":console.log("PAYMENT_INFO");console.log(action.data);
            return {
                ...state,
                data: action.data
            }

        default:
            return state;
    }
}
const TransactionAlreadyExists = (state = {status: 'loading'}, action) => {
    switch(action.type){
        case "EXISTS_TRANSACTION":
            return {
                ...state,
                status: action.status
            }

        default:
            return state;
    }
}



const UserReducer = combineReducers({
    UserAccount,
    ErrorMessage,
    Loader,
    Stories,
    Sections,
    Categories,
    StoryDetails,
    SubscriptionPlans,
    PageContents,
    Ads,
    ContactForm,
    SocialLinks,
    OTPSend,
    OTPverified,
    Subscriber,
    ProfileUpdate,
    ContinueWithEmail,
    Bookmarks,
    BookmarkStories,
    ForgotPassword,
    ResetPassword,
    PlaceOrder,
    PaymentInfo,
    TransactionAlreadyExists
})

export default UserReducer;
export const getUserAccount = state => state.UserReducer.UserAccount;
export const getErrorMessage = state => state.UserReducer.ErrorMessage;
export const getLoader = state => state.UserReducer.Loader;
export const getStories = state => state.UserReducer.Stories;
export const getSections = state => state.UserReducer.Sections;
export const getCategories = state => state.UserReducer.Categories;
export const getStoryDetails = state => state.UserReducer.StoryDetails;
export const getSubscriptionPlans = state => state.UserReducer.SubscriptionPlans;
export const getPageContents = state => state.UserReducer.PageContents;
export const getAds = state => state.UserReducer.Ads;
export const getContactForm = state => state.UserReducer.ContactForm;
export const getSocialLinks = state => state.UserReducer.SocialLinks;
export const getOTPSend = state => state.UserReducer.OTPSend;
export const getOTPverified= state => state.UserReducer.OTPverified;
export const getSubscriber= state => state.UserReducer.Subscriber;
export const getProfileUpdate= state => state.UserReducer.ProfileUpdate;
export const getContinueWithEmail= state => state.UserReducer.ContinueWithEmail;
export const getBookmarks= state => state.UserReducer.Bookmarks;
export const getBookmarkStories= state => state.UserReducer.BookmarkStories;
export const getForgotPassword= state => state.UserReducer.ForgotPassword;
export const getResetPassword= state => state.UserReducer.ResetPassword;
export const getPlaceOrder= state => state.UserReducer.PlaceOrder;
export const getPaymentInfo= state => state.UserReducer.PaymentInfo;
export const getTransactionInfo= state => state.UserReducer.TransactionAlreadyExists;
