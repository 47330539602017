import React, {Component} from 'react'
import Navbar from '../base_levels/Navbar'
import Filters from "../views/Filters";
import Ads from '../views/Ads';
import {getUrlSegment} from "../../utilities/CustomFunctions";
class Layout extends Component {
    constructor(props) {
        super();
        this.state = {
            adPosition: 0
        }

        this.listenToScroll = this.listenToScroll.bind(this);
    }
    listenToScroll=()=> {
        var scroll = document.querySelector('.main-wrapper').scrollTop;
        localStorage.setItem('scroll', scroll);
        let sectionList = document.querySelector('.section-main');
        let grids = sectionList.getElementsByClassName('each-grid');
        let obj = this;
        Array.from(grids).forEach(function (grid, gridIndx) {
            var position = grid.getBoundingClientRect();
            if(position.top < window.innerHeight && position.bottom >= 0) {

                let adIndx = 0;

                adIndx = parseInt((gridIndx) / 6);

                /*if(adIndx >= obj.props.ads.box.length) {
                    adIndx = obj.props.ads.box.length - 1
                }*/
                obj.setState({adPosition: adIndx});
            }
        });


    }
    componentDidMount() {
        this.setState({adPosition: 0});
        const container = document.querySelector('.main-wrapper');
        container.addEventListener('scroll', this.listenToScroll)

    }
    render() {
        let page = getUrlSegment(1) || "";
        let addnlClass = ((page === 'about-us') || (page === 'contact-us') ||
            (page === 'terms-of-use') || (page === 'subscribe') || (page === 'privacy-policy') || (page === 'plan')) ? ' sub-page' : '';
        let adPosition = this.state.adPosition;
        if(page === 'article') {
            adPosition = localStorage.getItem('adPosition') ? localStorage.getItem('adPosition') : (localStorage.getItem('box') ? localStorage.getItem('box') : 0);

            adPosition = parseInt(( parseInt(adPosition) + 1) / 6);

        }
        return (
            <div className={`main-wrapper`} id="main-wrapper">
               {/* <Scrollbars>*/}
                <Navbar loggedIn={this.props.loggedIn}
                        sections={this.props.sections}
                        changeSection={this.props.changeSection}
                        applySearch={this.props.applySearch}
                        filterToggler={this.props.filterToggler}
                        userLogout={this.props.userLogout}
                        bookmarks={this.props.bookmarks}
                        toggleModal={this.props.toggleModal}
                        socialLinks={this.props.socialLinks.data}/>
                <section className={`content-area${addnlClass}`}>
                    <div className="container">
                        <div className="content-area-inner">

                            <Filters
                                sections={this.props.sections}
                                categories={this.props.categories}
                                section={localStorage.getItem('section') || ''}
                                changeSection={this.props.changeSection}
                                filterNews={this.props.filterNews}
                                filterToggler={this.props.filterToggler}
                                filterTab={this.props.filterTab}
                                socialLinks={this.props.socialLinks.data}
                                loggedIn={this.props.loggedIn}
                                page={page}
                            />
                            <div className="section-main">
                                {this.props.children}
                            </div>
                            <Ads
                                ads={this.props.ads}
                                adPosition={adPosition}/>
                        </div>
                    </div>
                </section>
               {/* </Scrollbars>*/}
            </div>
        )
    }
}

export default Layout
