import React, { Component } from "react";



import * as actionReducers from "../../reducers";
import {connect} from "react-redux";
import * as ActionCreators from "../../actions";
import {getUrlSegment} from "../../utilities/CustomFunctions";
import {HOME_PAGE} from "../../constants";

class ResetPassword extends Component {
    constructor(props) {
        super();
        this.state = {
            password: '',
            confirmpassword: '',
            error: {
                password: '',
                confirmpassword: ''
            },
            message: ''

        }
        this.resetPassword = this.resetPassword.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {

    }
    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.resetPasswordMessage.message !== prevProps.resetPasswordMessage.message) {
            this.setState({message: this.props.resetPasswordMessage.message});
        }
    }


    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }
    resetPassword() {
        let errors = 0;
        let error = {
            password: '',
            confirmPassword: ''
        };
        if (this.state.password === "") {
            error["password"] = "Enter password";
            errors++;
        }
        if (this.state.confirmPassword === "") {
            error["confirmPassword"] = "Re-enter password";
            errors++;
        } else if (this.state.password !== this.state.confirmPassword) {
            error["confirmPassword"] = "Mismaching passwords";
            errors++;
        }
        if(errors === 0) {
            let url3 = getUrlSegment(3) || "";
            let url2 = getUrlSegment(2) || "";
            let email = window.atob(url2)
            let resetid = window.atob(url3)
            this.props.resetPassword({resetid:resetid, email: email, password: this.state.password});
        }
        this.setState({error: error});
    }
    render() {





        //console.log(window.atob(url1))

        return (
            <React.Fragment>

                <div className="login-wrapper">

                    <div className="login-panel">

                        <div className="login-header">
                            <a href="/">
                                <img src={`${HOME_PAGE}static/media/logo.1464e22b.svg`} className="light"  alt="logo" />
                                <img src={`${HOME_PAGE}static/media/logo-dark-theme.981862f9.svg`} className="dark"  alt="logo" />
                            </a>
                        </div>

                        <div className="login-content">
                            <div className="each-input-field">
                                <label>Password</label>
                                <input type="password" name="password" value={this.state.password}
                                       onChange={this.handleChange}/>
                                <div
                                    className={`error-field${this.state.error.password === '' ? ' hide' : ''}`}>{this.state.error.password}</div>
                            </div>
                            <div className="each-input-field">
                                <label>Confirm Password</label>
                                <input type="password" name="confirmPassword" value={this.state.confirmPassword}
                                       onChange={this.handleChange}/>
                                <div
                                    className={`error-field${this.state.error.confirmPassword === '' ? ' hide' : ''}`}>{this.state.error.confirmPassword}</div>
                                {(this.state.error.confirmPassword === '' && this.state.message !== '') &&
                                <div
                                    className={`error-field`}>{this.state.message}</div>
                                }
                            </div>


                            <div className="forgot">
                                <a href="../../../sign-in">Back to Login?</a>
                            </div>

                            <div className="submit-block">

                                <div className="direct-login-box">
                                    <button className="general-btn" onClick={this.resetPassword}>Submit</button>
                                </div>





                            </div>
                        </div>

                    </div>

                </div>


            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        loader: actionReducers.getLoader(state),
        resetPasswordMessage: actionReducers.getResetPassword(state),
    }
}
export default connect(mapStateToProps, {
    resetPassword: ActionCreators.resetPassword
})(ResetPassword);
